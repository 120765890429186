
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact_settings_dialog {
    @media #{$xs-and-down} {
        width: 100%;
        height: 100%;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .contact__dialog-body {
        padding: 0 20px;
        @media #{$xs-and-down} {
            padding: 0 10px;
            height: calc(100vh - 93px);
        }

        mat-form-field {
            width: 100%;
        }

        .content {
            padding-top: 20px;
            padding-bottom: 20px;
            @media #{$sm-and-up} {
                width: 450px;
            }
            .mat-checkbox {
                margin-top: 1px;
                &.mat-checkbox-checked {
                  .mat-checkbox-background {
                    background-color: $color-primary;
                  }
                }
          
                @media screen and (max-width: $mobile-breakpoint)  {
                  width: 18px;
                }
            }
            .start-authenticator-app {
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color:  $color-primary;
                cursor: pointer;
                font-size: 16px;
                margin-top: 15px;
            }
        }
    }
}