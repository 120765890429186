.advance-search-component {
    height: 100%;
    width: 100%;
    @media #{$xs-and-down} {
        width: 92%;
        margin-left: 40px;
    }
    .mat-dialog-container {
        border-radius: 0px;
        @media #{$xs-and-down} {
            overflow: hidden;
            padding: 20px 10px 0 10px;
        }
    }
    // Desktop view
    .desktop {
        .header-part {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .text {
                font-size: $extra-large-font-size;
                font-family: $manrop-font-family;
                font-weight: bold;
            }
            .close-icon {
                .mat-icon {
                    color: #888888;
                    cursor: pointer;
                }
            }
        }
        .content {
            margin-top: 6%;
            .search-field {
                text-align: center;
                position: relative;
                .mat-form-field {
                    width: 60%;
                    border: 1px solid #e3e3e3;
                    padding: 5px 13px 0 15px;
                    max-height: 60px;
                    border-radius: 6px;
                    .mat-form-field-underline {
                        display: none;
                    }
                    .mat-form-field-label-wrapper {
                        font-size: 20px;
                        top: -24px;
                        font-family: $sans-pro-font-family;
                    }
                    .mat-input-element {
                        font-size: 20px;
                    }
                    .saved-search-lbl {
                        background: black;
                        opacity: 0.7;
                        color: white;
                        border: 1px solid rgb(0, 0, 0, 0.7);
                        border-radius: 6px;
                        padding: 6px 14px 6px 14px;
                        font-size: $medium-font-size;
                        vertical-align: text-top;
                        margin: 0 10px 0 0;
                        font-family: $sans-pro-font-family;
                    }
                }
                .mat-icon {
                    color: #575757;
                    margin: 0 15px 0 0;
                    &.close-icon-item {
                        color: #888888;
                        cursor: pointer;
                    }
                    &.save-icon-item {
                        color: #888888;
                        cursor: pointer;
                    }
                }
                .save-search-dialog {
                    position: absolute;
                    z-index: 999;
                    background: white;
                    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
                    display: flex;
                    text-align: left;
                    left: 60%;
                    height: 62px;
                    width: 400px;
                    border-radius: 6px;
                    .mat-input-element {
                        border: 1px solid $theme-color;
                        height: 41px;
                        margin: 10px 0 0 15px;
                        border-radius: 6px;
                    }
                    button {
                        color: $theme-color;
                        font-size: $regular-font-size;
                        &:disabled {
                            color: #888888;
                        }
                    }
                }
                .suggestion-search {
                    position: absolute;
                    z-index: 999;
                    background: white;
                    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
                    left: 20%;
                    max-height: 200px;
                    min-height: 70px;
                    overflow: auto;
                    width: 300px;
                    border-radius: 6px;
                    top: 60px;
                    .suggest-item {
                        display: flex;
                        align-items: center;
                        height: 45px;
                        margin-top: 6px;
                        cursor: pointer;
                        .avatar {
                            margin: 10px 5px 10px 12px;
                            vp-avatar .avatar.list-avatar {
                                height: 32px;
                                width: 32px;
                                line-height: 32px;
                                font-size: 16px;
                            }
                        }
                        .full-name {
                            font-size: $regular-font-size;
                            font-family: $sans-pro-font-family;
                        }
                    }
                }
            }
            .advance-search-recent-activity-option {
                width: 60%;
                margin: 8px auto;
            }
            .search-operation {
                width: 60%;
                border: 1px solid #e3e3e3;
                margin: 11px auto;
                padding: 14px;
                border-radius: 6px;
                .filter-item-option {
                    .desktop-filter {
                        position: absolute;
                        background: $font-color-white;
                        z-index: 9;
                        transform: translate(26%, 0%);
                        padding: 8px;
                        border-radius: 6px;
                        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
                        width: 22%;
                    }
                    .back-drop {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        opacity: 0.3;
                        z-index: 1;
                    }
                    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
                    padding: 0 14px 0 14px;
                    .search-word {
                        display: flex;
                        align-items: center;
                        border-radius: 4px;
                        border: solid 1px $theme-color;
                        height: 28px;
                        margin: 15px 0 15px 0;
                        .mat-icon {
                            margin: 0 6px 0 0;
                            color: #888888;
                        }
                    }
                    .render-filters {
                        max-height: 260px;
                        overflow: auto;
                        padding: 5px;
                    }
                    .filters-name {
                        height: 28px;
                        line-height: 28px;
                        cursor: pointer;
                        line-height: 24px;
                        span {
                            font-family: $sans-pro-font-family;
                            font-size: $medium-font-size;
                        }
                        &.is-selected-item {
                            span {
                                font-family: $sans-pro-font-family;
                                font-size: $medium-font-size;
                                color: #a8a8a8;
                            }
                        }
                    }
                }
                .filter-add-button {
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    position: relative;
                    .add-filter-button {
                        font-size: $medium-font-size;
                        font-family: $manrop-font-family;
                        border-radius: 4px;
                        button {
                            background: $action-menu-color;
                            color: $font-color-white;
                            font-weight: bold;
                            border-radius: 6px;
                            span {
                                font-family: $manrop-font-family;
                            }
                        }
                        display: flex;
                        align-items: baseline;
                    }
                    .reset-save-search {
                        display: flex;
                        align-items: center;
                        font-size: $medium-font-size;
                        color: $theme-color;
                        font-weight: bold;
                        .reset-field {
                            margin: 0 25px 0 0;
                            font-weight: bold;
                            font-family: $manrop-font-family;
                        }
                        .save-search {
                            font-weight: bold;
                            font-family: $manrop-font-family;
                        }
                    }
                }
                .fill-items {
                    height: 220px;
                    overflow: auto;
                    .all-filter {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 4px 0 4px 0;
                        .filter-checkbox {
                            width: 25%;
                        }
                        .filter-operator {
                            width: 15%;
                            .mat-select {
                                width: 80px;
                                border: 1px solid #cecece;
                                line-height: 1.5;
                                padding: 5px;
                                border-radius: 6px;
                            }
                        }
                        .filter-values {
                            .mat-select {
                                width: 260px;
                                border: 1px solid #cecece;
                                line-height: 1.5;
                                padding: 5px;
                                border-radius: 6px;
                            }
                            .mat-input-element {
                                border: 1px solid #cecece;
                                height: 33px;
                                padding: 0 6px 0 6px;
                                border-radius: 6px;
                                width: 258px;
                            }
                            .list-item {
                                display: flex;
                                align-items: center;
                            }
                            select {
                                width: 272px;
                                border: 1px solid #cecece;
                                border-radius: 6px;
                                .multi-select-item {
                                    font-size: $medium-font-size;
                                    padding: 2px 0 0 8px;
                                    min-height: 1.5em;
                                    font-family: $sans-pro-font-family;
                                    &:checked {
                                        box-shadow: 0 10px 100px #eeeeee inset
                                    }
                                }
                            }
                            .mat-icon {
                                &.font-unfold-icon {
                                    color: #797979;
                                    margin: 0 0 0 10px;
                                }
                            }
                        }
                        .mat-checkbox {
                            .mat-checkbox-label {
                                font-size: 14px;
                                width: 142px;
                                display: block;
                                white-space: break-spaces;
                                font-family: $sans-pro-font-family;
                            }
                        }
                    }
                    .mat-form-field {
                        &.date-class {
                            width: 295px;
                            .mat-form-field-wrapper {
                                padding: 0px;
                                .mat-form-field-infix {
                                    display: contents;
                                }
                            }
                            .mat-datepicker-toggle {
                                margin: 0 0 0 -30px;
                                color: #888888;
                            }
                        }
                        .mat-form-field-underline {
                            display: none;
                        }
                    }
                }
                .fixed-content {
                    font-size: 14px;
                    font-style: italic;
                    color: #575757;
                }
            }
            .search-cancel {
                text-align: right;
                width: 81%;
                button {
                    font-size: $medium-font-size;
                    font-weight: bold;
                    span {
                        font-family: $manrop-font-family;
                    }
                    &.search {
                        color: $font-color-white;
                        background: $action-menu-color;
                        border-radius: 6px;
                    }
                    &.cancel {
                        color: $theme-color;
                    }
                }
            }
        }
    }

    // Mobile view
    .mobile {
        .header {
            .text {
                font-weight: bold;
                font-size: $regular-font-size;
                text-align: center;
                font-family: $manrop-font-family;
            }
        }
        .advance-search-recent-activity-option {
            margin: 10px 0 0 5px;
        }
        .search-item {
            display: flex;
            align-items: center;
            border: 1px solid #cecece;
            height: 40px;
            padding: 0 10px 0 10px;
            margin: 15px 0 0 0;
            border-radius: 4px;
            .mat-icon {
                &.close-icon-item {
                    margin: 0 15px 0 5px;
                }
                margin: 0 5px 0 5px;
                color: #888888;
            }
            .saved-search-lbl {
                background: black;
                opacity: 0.7;
                color: white;
                border: 1px solid rgba(0, 0, 0, 0.7);
                border-radius: 6px;
                padding: 6px 0 6px 0;
                font-size: $medium-font-size;
                vertical-align: text-top;
                margin: 0 12px 0 0;
                font-family: $sans-pro-font-family;
                width: 80%;
                text-align: center;
            }
        }
        .mobile-reset-save-search {
            display: flex;
            align-items: center;
            text-align: right;
            justify-content: flex-end;
            color: $theme-color;
            font-size: $medium-font-size;
            margin: 15px 0 0 0;
            .reset-field {
                margin: 0 20px 0 0;
                font-family: $sans-pro-font-family;
            }
            .save-search {
                font-family: $sans-pro-font-family;
            }
        }
        .add-filter-item {
            height: calc(100vh - 220px);
            overflow: auto;
            .item {
                padding: 0 11px 0 15px;
                margin-top: 8%;
            }

            .mobile-show-save-search {
                .back-drop {
                    position: absolute;
                    background: #000000;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0.3;
                    z-index: 1;
                }
                .searches {
                    position: absolute;
                    z-index: 2;
                    background: white;
                    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.27);
                    left: 50%;
                    border-radius: 4px;
                    transform: translate(-50%, 0%);
                    height: 400px;
                    overflow: auto;
                    .searches-options {
                        line-height: 38px;
                        width: 160px;
                        margin: 0 0 0 27px;
                        font-size: 14px;
                        font-family: $sans-pro-font-family;
                    }
                }
            }


            .mobile-show-save-search-dialog {
                .back-drop {
                    position: absolute;
                    background: #000000;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0.3;
                    z-index: 1;
                }
                .save-searches {
                    position: absolute;
                    z-index: 2;
                    background: white;
                    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.27);
                    left: 5%;
                    border-radius: 4px;
                    width: 80%;
                    padding: 12px;
                    top: 28%;
                    .searches-options {
                        line-height: 38px;
                        width: 160px;
                        margin: 0 0 0 27px;
                        font-size: 14px;
                        font-family: $sans-pro-font-family;
                    }
                    .header {
                        font-size: $regular-font-size;
                        font-family: $manrop-font-family;
                        font-weight: bold;
                        margin: 5px 0 15px 0;
                    }
                    .text-field {
                        .mat-form-field {
                            width: 95%;
                        }
                    }
                    .btn-footer {
                        text-align: right;
                        button {
                            color: $theme-color;
                            font-weight: bold;
                            font-size: $medium-font-size;
                            span {
                                font-family: $manrop-font-family;
                            }
                        }
                    }
                }
            }

            .all-filter {
                .mat-divider {
                    color: #e1e1e1;
                    margin: 14px 0 14px 0;
                }
                .checkbox-button {
                    display: flex;
                    align-items: center;
                    margin: 25px 0 15px 0;
                    .mat-checkbox {
                        width: 40%;
                        .mat-checkbox-label {
                            font-size: 14px;
                            width: 102px;
                            display: block;
                            white-space: break-spaces;
                        }
                    }
                    .mat-select {
                        width: 80px;
                        border: 1px solid #cecece;
                        line-height: 1.5;
                        padding: 5px;
                        border-radius: 6px;
                    }
                }
                .input-item {
                    .mat-select {
                        width: 100%;
                        border: 1px solid #cecece;
                        line-height: 1.5;
                        padding: 5px;
                        border-radius: 6px;
                    }
                    .mat-input-element {
                        border: 1px solid #cecece;
                        height: 28px;
                        padding: 0 6px 0 6px;
                        border-radius: 6px;
                        width: 80%;
                    }
                    .list-item {
                        display: flex;
                        align-items: center;
                    }
                    .single-item {
                        width: 80%;
                    }
                    select {
                        width: 100%;
                        border: 1px solid #cecece;
                        border-radius: 6px;
                        .option.multi-select-item {
                            font-size: $medium-font-size;
                            padding: 2px 0 0 8px;
                            min-height: 1.5em;
                            font-family: $sans-pro-font-family;
                            &:checked {
                                box-shadow: 0 10px 100px #eeeeee inset
                            }
                        }
                    }
                    .mat-icon {
                        &.fold-more {
                            margin: 0 0 0 20px;
                            color: #797979;
                        }
                    }
                    .mat-form-field {
                        &.date-class {
                            width: 100%;
                            .mat-form-field-wrapper {
                                padding: 0px;
                                .mat-form-field-infix {
                                    display: contents;
                                }
                            }
                        }
                        .mat-form-field-underline {
                            display: none;
                        }
                    }
                    .select-span {
                        width: 82%;
                    }
                }
            }

        }
        .mobile-show-filter {
            .back-drop {
                position: absolute;
                background: #000000;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0.3;
                z-index: 1;
            }
            .filters {
                position: absolute;
                z-index: 2;
                background: white;
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.27);
                left: 4em;
                border-radius: 4px;
                bottom: 3em;
                height: 22em;
                overflow: auto;
                .filter-options {
                    &.is-selected-item {
                        span {
                            color: #a8a8a8;
                        }
                    }
                    line-height: 38px;
                    width: 160px;
                    margin: 0 0 0 27px;
                    font-size: 14px;
                    font-family: $sans-pro-font-family;
                }
            }
        }
        .footer {
            display: flex;
            justify-content: space-between;
            button {
                font-size: $regular-font-size;
                font-family: $manrop-font-family;
                font-weight: bold;
                color: $theme-color;
                &.add-filter {
                    color: $font-color-white;
                    background: $action-menu-color;
                    border-radius: 4px;
                }
            }
        }
    }
}

.contact-plus-advance-search-mat-menu {
  .mat-menu-item { 
    padding: 0 0 0 10px;
    height: 38px;
    line-height: 38px;
    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        .mat-icon {
            color: #888888;
        }
    }
  }
}
.advance-search-filter-menu {
    margin: 128px -247px 0 0;
}

.country-select-menu {
    .mat-menu-item {
        &.country-search {
            display: flex;
            align-items: center;
            border: 1px solid $theme-color;
            height: 28px;
            margin: 8px 15px 0 16px;
            border-radius: 4px
        }
        .mat-icon {
            padding: 0px;
            margin: 0px;
            color: #888888;
        }
    }
    .country-list {
        overflow: auto;
        height: 200px;
    }
}