
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.create_contactplus_dialog {
    @media #{$xs-and-down} {
        height: 100%;
        width: 100%;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .mobile-submit-button {
        &:disabled {
            color: #FFFFFF;
            opacity: 0.3;
        }
    }
    .contact__dialog-body{
        overflow-x: hidden;
        font-size: 16px;
        padding: 16px;
        @media #{$sm-and-up} {
            max-height: 500px;
        }
        @media #{$xs-and-down} {
            height: calc(100vh - 51px);
            padding: 0px;
        }
        &.is-show-submit {
            @media #{$xs-and-down} {
                height: calc(100vh - 95px);
            }
        }
    }
    .content {
        width: 100%;
        .user-avatar {
            padding-right: 10px;
            .avatar {
                &.list-avatar {
                    border-radius: 50%;
                    overflow: hidden;
                    height: 48px;
                    width: 48px;
                    line-height: 52px;
                    font-size: 20px;
                    text-align: center;
                    display: inline-block;
                    color: white;
                    background-color: #ededed;
                    cursor: pointer;
                }
                .profile-avtar {
                    font-size: 48px;
                }

                .mat-icon {
                    font-size: 20px;
                    color: #888;
                }
            }
        }
        .form-group {
            display: flex;
            align-items: center;
            width: 100%;
            &.company-field {
                display: none;
            }

            &.padding-top-bottom-10 {
                padding: 10px 0;
            }
            .duplicate-warning {
                font-size: 11px;
                color: red;
                margin: 5px 5px;
            }
            .field_icon {
                padding: 0 16px;
                width: 30px;

                .mat-icon {
                    font-size: 18px;
                    color: #888;
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                    &.brand {
                        color: #317bbc;
                    }
                    &.middle-name-icon {
                        @media #{$xs-and-down} {
                            display: none;
                        }
                    }
                }

                @media(max-width: $layout-breakpoint-sm) {
                    align-self: flex-start;
                    margin: 12px 0 0 0;
                    &.margin-0 {
                        margin: 0px;
                    }
                }
            }
            .fields {
                display: flex;
                align-items: center;
                width: 100%;
                @media(max-width: $layout-breakpoint-sm) {
                    flex-wrap: wrap;
                }
                .field_item {
                    width: 100%;
                    font-size: 14px;
                    font-family: $field_font;

                    &.middlename {
                        width: 100%;
                    }
                    &.phone {
                        &.phone-flag {
                            display: flex;
                            @media #{$xs-and-down} {
                                display: initial;
                            }
                            align-items: center;
                            justify-content: space-between;
                            .flag-form-field {
                                width: 25%;
                                height: 20px;
                                cursor: pointer;
                                @media #{$xs-and-down} {
                                    margin-right: 10px;
                                    display: initial;
                                    vertical-align: middle;
                                }
                                span.country-flag {
                                    font-size: 24px;
                                    width: 24px;
                                    display: inline-block;
                                }
                                span.icon {
                                    color: #888888;
                                    margin: 0 0 0 8px;
                                    vertical-align: middle;
                                }
                            }
                            .phone-number-form-field {
                                width: 70%;
                            }
                        }
                    }
                    &.state_field {
                        width: 100%;
                    }
                    &.small_field {
                        @media #{$sm-and-up} {
                            width: 35%;
                        }
                    }
                    &.add_more {
                        font-family: Manrope;
                        font-size: 14px;
                        font-weight: bold;
                        text-align: left;
                        color: #317bbc;
                        cursor: pointer;
                    }
                    &.contact-chips-list {
                        .mat-chip-list-wrapper {
                            max-width: fit-content;
                        }

                        .mat-chip {
                            background: #eaeef2;
                            height: 24px;
                            /* margin: 2px 0; */
                            /* padding: 0 0 0 29px; */
                            padding: 6px;
                            border-radius: 16px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #000000;
                            .mat-icon {
                                font-size: 10px;
                                color: #000000;
                                line-height: 1.8;
                            }
                            span.name {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 140px;
                            }
                        }
                    }
                    &.list-field-item {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                        color: #888888;
                        cursor: pointer;
                        .list-item {
                            float: right;
                            .mat-icon {
                                font-size: 18px;
                                color: #888888;
                            }
                        }
                    }
                    &.postal_code {
                        @media(max-width: $layout-breakpoint-sm) {
                            width: 45%;
                            padding: 0 10% 0 0;
                        }
                    }
                    &.contact_city {
                        @media(max-width: $layout-breakpoint-sm) {
                            width: 45%;
                        }
                    }
                    &.country-code {
                        @media(max-width: $layout-breakpoint-sm) {
                            width: 45%;
                            padding: 0 10% 0 0;
                        }
                    }
                    &.address-type {
                        @media(max-width: $layout-breakpoint-sm) {
                            width: 45%;
                        }
                    }
                }
                .mat-checkbox {
                    padding: 0 16px;
                    padding-bottom: 12px;

                    .mat-checkbox-label {
                        padding-left: 16px;
                        color: #888;
                    }
                }
                &.chip-list-field {
                    margin: 10px 0 0 0;
                }
            }
            .mat-form-field {
                width: 100%;
                &.mobile-width-40 {
                    @media #{$xs-and-down} {
                        width: 40%;
                    }
                }
                &.mobile-width-90 {
                    @media #{$xs-and-down} {
                        width: 90%;
                    }
                }
                .mat-form-field-label {
                    @media #{$xs-and-down} {
                        color: #888888;
                    }
                }
                .mat-datepicker-toggle-default-icon {
                    color: #888888;
                }
            }
            .mat-select {
                .mat-select-value-text{
                }
            }
            .right-space-div {
                width: 70px;
                display: flex;
                justify-content: flex-end;
                @media #{$xs-and-down} {
                    display: grid;
                    justify-content: center;
                }
            }
            .add-remove-button {
                button {
                    height: 25px;
                    width: 25px;
                    border-radius: 18px;
                    min-width: 25px;
                    line-height: 0;
                    padding: 0;

                    .mat-icon {
                        color: #29b6f6;
                        font-size: 20px;
                        &.remove {
                            color: #888;
                        }
                        &.check {
                            color: #08d600;
                        }
                    }
                }
            }
            &.margin-10 {
                margin: 21px 0 10px 0;
            }
        }
        .contcat-dual-fields {
            @media #{$sm-and-up} {
                .field_item:first-child {
                    margin-right: 10px;
                }
            }
        }

        .form-group-address {
            align-items: center;
            .fields {
                display: flex;
                align-items: center;
            }
            .flag-address-item-div {
                height: 20px;
                cursor: pointer;
                border-bottom: 1px solid #888888;
                @media #{$xs-and-down} {
                    margin-right: 10px;
                    display: flex;
                    vertical-align: middle;
                }
                span.country-item-name {
                    @media #{$xs-and-down} {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 103px;
                        display: block;
                    }
                }
                span.icon {
                    color: #888888;
                    margin: 0 0 0 8px;
                    vertical-align: middle;
                    float: right;
                    display: none;
                }
            }
        }

        .more-fields {
            display: flex;
            margin: 5px 0px;
            @media #{$sm-and-up} {
                display: none;
            }
            button {
                cursor: pointer;
                color: #317bbc;
                font-size: 16px;
            }
        }
    }
    .contact-profile-avatar {
        background: #f6f4fa;
        height: 130px;
        text-align: center;
        padding-top: 15px;
        margin: 0 0 10px 0;
        border-bottom:  1px solid #b7b7b7;
        .avatar-upload {
            display: flex;
            justify-content: center;
            padding-left: 53px;
            margin-top: -38px;

            .mat-button {
                border-radius: 50%;
                height: 37px;
                width: 37px;
                min-width: 37px;
                justify-content: center;
                display: flex;
                background: #29b6f6;
                align-items: center;

                .mat-icon {
                    height: 18px;
                    width: 18px;
                    font-size: 18px;
                    color: white;
                }
            }
        }
        .user-avatar {
            .avatar {
                &.list-avatar {
                    border-radius: 50%;
                    overflow: hidden;
                    height: 94px;
                    width: 94px;
                    line-height: 94px;
                    font-size: 60px;
                    text-align: center;
                    display: inline-block;
                    color: white;
                    background-color: #ededed;
                    cursor: pointer;
                    border: 1px solid #fff;
                }
                .profile-avtar {
                    font-size: 48px;
                }

                .mat-icon {
                    font-size: 60px;
                    color: #888;
                    vertical-align: middle;
                    height: 60px;
                    width: 60px;
                }
            }
        }
    }

    .contact__dialog-footer {
        .desktop-footer {
            width: 100%;
            justify-content: flex-end;

            @media #{$xs-and-down} {
                display: none;
            }
        }

        .more-fields {
            padding-left: 20px;
            display: flex;
            @media #{$xs-and-down} {
                display: none;
            }
            button {
                cursor: pointer;
                color: #317bbc;
                font-size: 16px;
            }
        }

    }

    .desktop-hide {
        @media #{$sm-and-up} {
            display: none;
        }
    }

    .mobile-hide {
        @media #{$xs-and-down} {
            display: none;
        }
    }

    .separator-div {
        height: 1px;
        background-color: #f4f4f4;
        margin: 10px 0px;
    }
}
.country-search {
    width: 92%;
    padding: 0 0 0 10px;
    .mat-icon {
        color: #888888;
    }
}
.contact-country-mat-menu {
    .drop-down-flag {
        font-size: 18px;
        margin-right: 12px
    }
    .country-name-lbl {
        font-size: 14px;
        font-weight: bold;
        margin-right: 12px
    }
    .country-dial-code {
        font-size: 14px;
        color: #888888;
    }
    .list-scrollbar-item {
        height: 200px;
        overflow: auto;
    }
}
.search-country-option-scroll {
    overflow: auto;
    height: 197px;
}

.add-list-incontact{
    .contact__dialog-body{
        .content{
            .main{
                .group-name{
                    .name{
                        width: 98%;
                    }
                }
            }
        }
    }
}