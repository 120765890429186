
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact_folder-create_dialog {
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .mat-dialog-container {
        padding: 20px 24px 0px 24px;
    }

    .mat-form-field-hint-wrapper {
        float: right;
    }

    .content {
        .mat-tab-body-wrapper {
            padding: 18px;
        }

        .message {
            font-size: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: $dialog_header_title_font;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.83;
            letter-spacing: normal;
            color: #000000;
            margin-bottom: 8px;
        }

        .edit-delegated-permission {
            color: rgba(0, 0, 0, 0.87);
            font-size: 18px;
            margin-bottom: 20px;
        }

        .mat-input-element {
            caret-color: $mobile-textbox-focused-color;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
        }

        .mat-form-field-label-wrapper {
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
        }

        .mat-form-field {
            width: 100%;
            font-size: 16px;

            &.mat-focused .mat-form-field-ripple {
                background-color: $mobile-textbox-focused-color;
            }

            &.mat-focused .mat-form-field-label {
                color: $mobile-textbox-focused-color;
            }
        }

        .color-action {
            display: flex;
            padding-top: 7px;
            align-items: center;

            .mat-button {
                height: 24px;
                width: 24px;
                padding: 0;
                min-width: 24px;
                padding-top: 2px;
            }
        }

        .actions {
            padding-top: 12px;
            float: right;

            a {
                width: 52px;
                height: 11px;
                font-size: 14px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 3.07;
                letter-spacing: normal;
                text-align: left;
                color: $brand-color;
                text-transform: uppercase;
                padding-left: 20px;
                cursor: pointer;

                &.action-disabled {
                    color: #949494;
                    cursor: default;
                }
            }

            button {
                font-size: 13px;
                letter-spacing: .5px;
                text-align: center;
                color: #777777;
                margin-left: 4px;
                text-transform: uppercase;

                &.save_btn {
                    background: #007dc3;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .24), 0 0 2px 0 rgba(0, 0, 0, .12);
                    color: #FFFFFF;

                    &.btn-disabled {
                        background: #949494;
                    }
                }
            }
        }
        &.add-delegate-content {
            .delegate-rights-checkbox {
                display: block;
                .mat-checkbox {
                    width: 100%;
                    display: flex;
                }
            }
        }
    }

    .mail-tags {
        width: 100%;
        white-space: nowrap;
        height: 31px;
        overflow-x: overlay;

        .tag-icon {
            float: left;
            color: #888888;
            margin-right: 10px;
        }

        .mail-tag {
            height: 18px;
            border-radius: 3px;
            color: white;
            font-size: 12px;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            max-width: 200px;
            margin-right: 5px;
            padding: 0 5px;
            margin-bottom: 5px;
            cursor: pointer;

            span {
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .mat-icon {
                font-size: 12px;
                width: 12px;
                height: 12px;
                cursor: pointer;
                margin-left: 5px;
            }
        }
    }
}

.create-folder-color-selection, .color-selection-radio {
    .mat-radio-button {
      padding-right: 14px;
      @media #{$xs-and-down} {
        padding-right: 7px;
      }
    }
    .mat-radio-outer-circle {
      height: 28px;
      width: 28px;
    }
    .mat-radio-inner-circle {
      height: 28px;
      width: 28px;
    }
    .mat-radio-container {
      width: 28px;
      height: 28px;
    }
    .mat-radio-button .mat-radio-ripple {
      left: calc(50% - 28px);
      top: calc(50% - 28px);
      height: 56px;
      width: 56px;
    }
  
    .mat-radio-checked .mat-radio-inner-circle {
      transform: scale(.5);
    }
  
    .grey-radio {
      .mat-radio-outer-circle {
        background-color: #607d8b;
        border-color:  #607d8b;
      }
      &.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        background: white;
        border-color:  #607d8b;
      }
      &.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {      background-color: #999;
        background-color: #607d8b;
      }
    }
  
    .sky-radio {
      .mat-radio-outer-circle {
        background-color: #00b8d4;
        border-color:  #00b8d4;
      }
      &.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        background: white;
        border-color:  #00b8d4;
      }
      &.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {      background-color: #999;
        background-color: #00b8d4;
      }
    }
  
    .blue-radio {
      .mat-radio-outer-circle {
        background-color: #20ae80;
        border-color:  #20ae80;
      }
      &.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        background: white;
        border-color:  #20ae80;
      }
      &.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {      background-color: #999;
        background-color: #20ae80;
      }
    }
  
    .navy-radio {
      .mat-radio-outer-circle {
        background-color: #6200ea;
        border-color:  #6200ea;
      }
      &.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        background: white;
        border-color:  #6200ea;
      }
      &.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {      background-color: #999;
        background-color: #6200ea;
      }
    }
  
    .red-radio {
      .mat-radio-outer-circle {
        background-color: #d50000;
        border-color:  #d50000;
      }
      &.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        background: white;
        border-color:  #d50000;
      }
      &.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {      background-color: #999;
        background-color: #d50000;
      }
    }
    .orange-radio {
      .mat-radio-outer-circle {
        background-color: #fd8100;
        border-color:  #fd8100;
      }
      &.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        background: white;
        border-color:  #fd8100;
      }
      &.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {      background-color: #999;
        background-color: #fd8100;
      }
    }
    .green-radio {
      .mat-radio-outer-circle {
        background-color: #39b54a;
        border-color:  #39b54a;
      }
      &.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        background: white;
        border-color:  #39b54a;
      }
      &.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {      background-color: #999;
        background-color: #39b54a;
      }
    }
}

.create-folder-color-selection, .color-selection-radio {
    .mat-radio-container, .mat-radio-outer-circle, .mat-radio-inner-circle {
      width: 14px;
      height: 14px;
    }
    .mat-radio-button .mat-radio-ripple {
      left: calc(50% - 14px);
      top: calc(50% - 14px);
      height: 28px;
      width: 28px;
      display: none;
    }
    .mat-accent.mat-radio-checked {
      .mat-radio-inner-circle {
        transform: none;
      }
      .mat-radio-outer-circle {
        transform: scale(1.7);
        border-width: 1px;
      }
    }
}