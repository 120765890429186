.contact_recent_activity_dialog {
    height: 100%;
    width: 100%;
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        border-radius: 0px;
    }
    .recent-activity {
        .mat-tab-body-wrapper {
            height: calc(100vh - 100px);
        }
        .mat-tab-group {
            .mat-ink-bar {
                background: $theme-color;
                height: 3px;
                width: 111px !important;
            }
            .mat-tab-label {
                opacity: 1;
                min-width: 110px;
                .mat-tab-label-content {
                    font-family: $manrop-font-family;
                    color: #888888;
                }
                &.mat-tab-label-active {
                    .mat-tab-label-content {
                        color: $theme-color;
                        opacity: 1;
                        font-weight: bold;
                    }
                }
            }
            .mat-tab-list {
                border-bottom: 1px solid #f1f1f1;
            }
            .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
                display: none;
            }
        }
        .recent-contact-tab {
            .recent-item {
                cursor: pointer;
                .left {
                    margin: 0px 10px 0 10px;
                    .avatar-item {
                        vp-avatar {
                            .list-avatar {
                                height: 36px;
                                width: 36px;
                                line-height: 36px;
                                // margin: 0 12px 0 10px;
                            }
                        }
                    }
                }
                .right {
                    .title {
                        margin: 0 15px 0 0;
                        font-size: 14px;
                        font-family: $sans-pro-font-family;
                        .item-type {
                            color: #888888;
                        }
                        .title-content {
                            font-family: $sans-pro-font-family;
                        }
                    }
                    .time {
                        font-size: 12px;
                        font-family: $sans-pro-font-family;
                        color: #888888;
                        font-family: $sans-pro-font-family;
                    }
                }
                .mat-divider {
                    margin: 1% 0 0 1%;
                    color: #e1e1e1;
                }
                .recen-item-inner {
                    display: flex;
                    margin: 10px 0 0 0;
                }
            }
        }
    }
}