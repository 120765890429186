
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

vp-sidebar .vp-sidebar .sidebar-block-features:first-child {
  margin-top: 0px;
}

vp-sidebar {
  height: 100%;
  .vp-sidebar {
    position: relative;
  }
  .sidebar-item {
    display: flex;
    align-items: center;
  }
  .contact-sidebar-main-division {
    height: calc(100vh - 108px);
    overflow: auto;
  }
  .contact-sidebar-block {
    // height: calc(100% - 62px);
    overflow-y: hidden;
    overflow-x: hidden;
    .feature-title {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
      color: #000;
    }
    .sidebar-item {
      border-width: 0;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;
      height: 52px;
      line-height: 52px;
      margin-bottom: 2px;
      padding-right: 10px;
      outline: none;
      word-break: break-all;
      &:hover {
        .contact-sublist-action {
          display: block;
        }
      }
      .contact-sublist-action {
        width: 150px;
        text-align: right;
        padding-right: 10px;
        display: none;
      }
      &.collapsible {
        height: 45px;
        line-height: 45px;
        background: #e5e5e5;
        border-bottom: 2px solid#d3d3d3;
      }
      .item-icon {
        height: 100%;
        min-width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        padding-bottom: 2px;
        img {
          height: 30px;
          width: 30px;
          padding: 3px;
        }
      }
      .rounded-rectangle {
        width: 41px;
        height: 25px;
        align-content: center;
        align-self: center;
        justify-content: center;
        display: grid;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
      }
      .tag-title {
        color: black;
        align-self: stretch;
        order: 0;
        overflow: hidden;
        width: 150px;
        vertical-align: top;
        padding-left: 75px;
        font-style: italic;
      }
      .item-title {
        color: black;
        align-self: stretch;
        order: 0;
        overflow: hidden;
        width: auto;
        vertical-align: top;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: -0.1px;
        margin-right: 7px;
        width: 184px;
      }
      .item-badge {
        order: 0;
        flex: 0 1 auto;
        align-self: stretch;
        width: 50px;
        vertical-align: middle;
        overflow: hidden;
        text-align: right;
        span {
          background-color: #FE4100;
          border-radius: 30px;
          color: white;
          display: inline-block;
          font-size: 11px;
          height: 20px;
          line-height: 10px;
          min-width: 20px;
          padding: 5px;
          text-align: center;
        }
      }
      &.active {
        .contact-sublist-action {
          display: block;
        }
        background-color:$commander-standard-color;
        color: white;
        .item-title {
          color: white;
        }
        .item-icon {
          color:white;
        }
        .rounded-rectangle {
          width: 41px;
          height: 25px;
          mix-blend-mode: undefined;
          border-radius: 12.5px;
          background-color: $commander-standard-color;
          align-content: center;
          align-self: center;
          justify-content: center;
          display: grid;
          font-size: 12px;
        }
        &:before {
          background-color: #5997DF;
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 4px;
        }
      }
    }
    .contact-list {
      display: flex;
      align-items: center;
      // background: #317bbc;
      // color: white;
      height: 45px;
      font-size: 14px;
      padding: 0 19px 0 18px;
      cursor: pointer;
      justify-content: space-between;
      white-space: nowrap;
      .mat-icon {
        font-size: 28px;
        margin: 0 20px 0 0;
        color: #888888;
        &.hover-icon {
          display: none;
        }
      }
      span {
        font-size: $regular-font-size;
        &.text {
          font-size: $medium-font-size;
          font-family: $sans-pro-font-family;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.count {
          color: #888888;
        }
      }
      div {
        display: flex;
        align-items: center;
        min-width: 10px;
      }
      &.list-selected {
        background: $selection-color;
        // color: $white-color;
      }
      &:hover {
        span {
          &.count {
            display: none;
          }
        }
        .hover-icon {
          display: block;
          margin: 0px;
          width: 14px;
        }
      }
      mat-menu {
        position: absolute;
      }
    }
    .head-item {
      display: flex;
      justify-content: space-between;
      font-size: $regular-font-size;
      font-weight: bold;
      margin-left: 18px;
      line-height: 3;
      .icon-item {
        margin: 0 13px 0 0;
        .icon-options {
          cursor: pointer;
          margin: 0 0 -2px 10px;
          g{
            g {
              fill: #525252; //neutral_600
            }
          }

          &:hover {
            g{
              g {
                fill: #262626; //neutral_800
              }
            }
          }
        }
      }
      .head-main-item {
        font-family: $manrop-font-family;
        .list-count {
          font-weight: normal;
          color: #888888;
        }
        .mat-icon {
          cursor: pointer;
          &.gray-color {
            color: #888888;
          }
        }
        .head-mat-icon {
          .mat-icon {
            color: #888888;
            margin: 0 0 0 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
  &.collapsed {
    .feature-title {
      display: none;
    }
    .sidebar-item {
      padding-right: 0;
      .item-title {
        display: none;
      }
      .item-badge {
        display: none;
      }
    }
  }
  .item-divider {
    position: relative;
    border: none;
    height: 1px;
    background: #e1e1e1;
  }
  .create-group-sidebar-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    line-height: 35px;
    outline: none;
    width: 95%;
    position: relative;
    margin: auto;
    margin-top: 5px;
    &:hover {
      .dropbox {
        display: block;
      }
    }

    .center-placeholder {
      background: #f5f5f5; //neutral_100
      width: 100%;
      height: 33px;
      border-radius: 10px 0 0 10px;
      border: none;
      padding-left: 28px;
      outline: none;
      font-size: 14px;
      margin-right: 1px;
    }

    .search-icon {
      position: absolute;
      left: 7px;
      top: 5px;
      cursor: pointer;
      width: 20px;
      g{
        fill: #888888;
      }
    }

    .add-group-btn {
      cursor: pointer;
      background: #388eda;
      height: 35px;
      width: 53px;
      border-radius: 0 10px 10px 0;
      outline: 0;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: none;

      div {
        .add-new {
          margin-top: 2px;
          g{
            fill: #fff;
          }
        }
      }

      &:hover {
        background: #317bbc;
      }
    }
  }
  button {
    padding: 0;
    outline: 0;
    &.toggle-panel-btn {
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -48px;
      background: #fff;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border: 1px solid #dadada;
      background-color: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      position: absolute;
      left: calc(#{$sidebar-width} - 15px);
      top: 60px;
      z-index: 999;
      &.collapsed {
        left: 48px;
      }
      .mat-icon {
        vertical-align: middle;
        font-size: 16px;
        color: #888888;
      }
    }
  }
}

.kebab-icon{
  cursor: pointer;
  display: none !important;
}

.share-contact{
  align-items: center;
  display: flex;
  width: 240px;
  min-height: 36px !important;
  button, .mat-menu-item{
    align-items: center;
    height: 36px;
    display: flex;
    padding: 8px 0;
    width: 240px;
    &:hover{
      background-color: #f7f8fa;
    }
  }
  vnc-icon-container{
    margin-right: 8px;
    padding: 6px 0 6px 12px;
  }
  span{
    margin: 2px 0;
    font-family: $sans-pro-font-family;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: -0.14px;
    color: #131313;
  }
}

.contact-sidebar-context-menu {
  .context-item {
    display: flex;
    align-items: center;
    vnc-icon-container{
      margin-right: 8px;
      padding: 6px 0 6px 12px;
    }
    span{
      margin: 2px 0;
      font-family: $sans-pro-font-family;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: inherit;
      letter-spacing: -0.14px;
      color: #131313;
    }
    &:hover{
      background-color: #f7f8fa;
    }
  }
}

.context-list-item {
  padding: 8px;
  display: flex;
  font-size: 16px;
  cursor: pointer;
  svg{
    margin-right: 15px;
  }
  .mat-icon {
    font-size: 16px;
    line-height: inherit;
    margin-right: 15px;
    color:#888;
  }
  span {
    font-size: $medium-font-size;
    font-family: $sans-pro-font-family;
  }
}

.ngx-contextmenu {
  a{
    padding: 0px !important;
  }
  li{
    padding: 0px !important;
  }
  .dropdown-menu{
    border-radius: 4px !important;
    margin: 14px 0 0 0;
    padding: 8px 0 !important;
    width: 240px;
  }
}