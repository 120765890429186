
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact-service-desk-dialog {
    @media #{$xs-and-down} {
        height: 100%;
        width: 100%;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
    }
    .content {
        padding: 15px;
        max-width: 450px;
        .text {
            font-family: Source Sans Pro;
            font-size: 16px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: #000;
        }
    }
    .link {
        text-align: center;
        a {
            font-family: Manrope;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: center;
            color: #0071bb;
            text-decoration: none;
        }
        img {
            border-radius: 50%;
            border: 2px solid #888888;;
            -o-object-fit: cover;
            object-fit: cover;
            width: 74px;
            height: 74px;
            -o-object-position: top;
            object-position: top;
        }
    }
}