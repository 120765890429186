
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.mobile-group-detail-dialog {
    @media #{$xs-and-down} {
        height: 100%;
        width: 100%;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .member-items {
        display: flex;
        align-items: center;
        margin: 10px;
        .fullname {
            margin: 0 0 0 15px;
        }
    }
    .search-header {
        background: white;
        height: 51px;
        box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.5);
        .mat-icon {
            color: #000000;
        }
        .mat-form-field {
            width: 100%;
            color: #000000;
            .mat-form-field-wrapper {
                .mat-form-field-underline {
                    display: none;
                }
            }
            &.mat-focused {
                .mat-form-field-label {
                    color: rgba(0, 0, 0, 0.54);
                }
            }
        }
    }
}