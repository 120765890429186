
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

vp-contact-item {
    .contact-item {
      background: #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: table;
      height: 77px;
      table-layout: fixed;
      -webkit-transition: .1s all;
      transition: .1s all;
      width: 100%;
      padding: 10px;
      position: relative;
      .item-action-display {
        display: block;
        @media #{$xs-and-down}, #{$sm-and-down} {
          display: none;
        }
      }
  
      @media #{$xs-and-down}, #{$sm-and-down} {
        padding: 0px;
      }
  
      &:hover {
       // background: #eee;
        cursor: pointer;
      }
  
      &.checked {
       // background: #eee;
        cursor: pointer;
      }
  
      &_icons {
        display: inline-block;
        font-family: 'Material Icons';
        font-size: 24px;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
      }
      &_avatar {
        height: 40px;
        width: 40px;
        line-height: 36px;
        box-sizing: border-box;
        border-radius: 50%;
        background-color: #0079a6;
        font-size: 20px;
        color: white;
        text-align: center;
        text-transform: uppercase;
      }
      .favourite_color{
        color:#ffcc00;
      }
      .contact_action_menu{
        margin-top: 40px;
        right:11px;
        box-shadow: 0 -1px 1px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
        max-width: 200px;
        &.popover-upward{
          bottom: 65px;
        }
        .mat-list__item{
          font-size: 14px;
          text-align: left;
          &.border_bottom{
            border-bottom: 1px solid rgba(0,0,0,.12);
          }
          &.desktop_menu{
            display: block;
            @media(max-width: $layout-breakpoint-sm) {
              display: none;
            }
          }
          &.mobile-view {
            display: none;
            @media #{$xs-and-down}, #{$sm-and-down} {
              display: block;
            }
          }
          &:hover{
            background: #eeeeee;
            cursor: pointer;
          }
          &.change_list_lbl{
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: -.1px;
            text-align: left;
            color: #575757;
          }
          .material-icons{
            color: #010101;
            font-size:20px;
            text-align: center;
            vertical-align: middle;
            margin-right: 12px;
          }
        }
      }
    }
  }
  
  .table-cell {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: table-cell;
      font-size: 14px;
      overflow: hidden;
      padding-left: 10px;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
      .group-avatar {
        border-radius: 50%;
        overflow: hidden;
        height: 43px;
        width: 43px;
        line-height: 51px;
        font-size: 20px;
        text-align: center;
        display: inline-block;
        color: white;
        margin-top: 1px;
        background: blue;
      }
  }
  
  .icon-cell {
    padding-left: 12px;
    width: 68px;
  }
  
  .fullname {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.97;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    span {
      font-family: $manrop-font-family;
    }
  }
  
  .email, .phone {
    width: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.97;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
  
  @media (min-width: 900px) {
  .email {
      width: 26%;
    }
  }
  @media (min-width: 1100px) {
  .email, .phone {
      width: 20%;
  }
  }
  @media (min-width: 1300px) {
  .email, .phone {
      width: 26%;
  }
  }
  
  .option-cell {
    padding-bottom: 11px;
    .action-option-location {
      display: flex;
      padding-top: 9px;
      float: right;
      @media(max-width: $layout-breakpoint-sm) {
        padding-left: 13px;
        padding-top: 17px;
      }
    }
    @media(max-width: 1023px) {
      display: none;
    }
  }
  
  .mat_button_option {
    min-width: 35px;
    padding: 0 ;
    &.__mobile-view {
      @media(max-width: $layout-breakpoint-sm) {
        display: none;
      }
    }
  }
  .avatar-select-md {
    display: inline-block;
    border-radius: 50%;
    color: #fff;
    min-width: 42px;
    width: 42px;
    height: 42px;
    line-height: 42px;
    background: #29b6f6;
    text-align: center;
    overflow: hidden;
  }
  .contact-checkbox {
    font-family: 'Material Icons Extended';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-rendering: optimizeLegibility;
    text-transform: none;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #666;
    cursor: pointer;
    height: 40px;
    left: 5px;
    outline: 0;
    padding-top: 8px;
    padding-left: 8px;
    position: relative;
    width: 40px;
    @media(max-width: 1023px) {
      display: none;
    }
  }
  
  .avtar-desktop-visibility {
    @media(max-width: 1023px) {
      display: none;
    }
  }
  .avtar-disable {
    @media(max-width: $layout-breakpoint-sm) {
      display: block;
    }
    display: none;
  }
  
    .avtar-mobile-visibility {
      @media(min-width: 1024px) {
        display: none;
      }
  }
  
  vp-contact-add-menu {
    .overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,.4);
      z-index: 4;
    }
    .contact-add-menu-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: fixed;
      bottom: 15px;
      padding-right: 17px;
      right: 0;
      text-align: right;
      z-index: 6;
      &__description {
        font-size: 14px;
        font-weight: 100;
      }
      .mat-tooltip {
        border-radius: 2px;
        font-size: 16px;
        z-index: 0;
        position: relative;
        top: 0px;
        left: 0px;
        margin-right: 20px;
        max-width: 77%;
        line-height: 20px;
      }
      mat-list-item:hover {
        .mat-tooltip {
          transform: scale(1);
        }
      }
      mat-list-item {
        overflow: visible;
        mat-button {
          box-shadow: 0px 1.5px 6px 1px rgba(0, 0, 0, 0.3);
        }
      }
    }
    .mat-list {
      padding: 0;
      margin: 4px 8px;
      &__item {
        color: #fff;
        margin: 8px 0;
        padding: 0;
        .mat-button--fab {
          background-color: #fff;
          // @include fab-button(42px, 19px);
        }
        &-primary-content {
          cursor: pointer;
        }
      }
  
      @media #{$xs-and-down} {
        margin: 2px 4px;
        &__item {
          margin: 5px 0;
          .mat-button--fab {
            // @include fab-button(40px, 17px);
          }
        }
      }
  
    }
    .mat-list__item .mat-list__item-primary-content {
      display: block;
    }
  }
  