
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

 .vnc-authentication {
  background: no-repeat center center;
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0px;
  z-index: 1;
  *, *:before, *:after {
    box-sizing: border-box;
  }
  h4.vnctalk-heading {
    text-align: center;
    color: white;
    margin-top: 80px;
  }
  form {
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
    margin: 30px 12px;
    margin-bottom: 130px;
    padding: 0 32px;
    border-radius: 12px;
    .form-control {
        input {
          font-size: 16px;
          font-family: Roboto;
          height: 46px;
          border: 1px solid #dadee2;
          border-radius: 6px;
          padding: 0 16px;
          &:focus {
            border-bottom: 2px solid #388eda;
          }
      }
    }
  }
  button.login-server {
    background-color: #27ab7f;
    color: white;
    width: 100%;
    border: none;
    padding: 10px;
    margin-top: 56px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-family: Manrope;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: center;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    -webkit-appearance: none;
    height: 64px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(7,29,51, 0.2), 0 0 2px 0 rgba(7,29,51, 0.1);
    &:hover, &:focus {
      outline: 0;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }
  .separator {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .form-control input {
    color: #000000;
    background-color: white;
    width: 100%;
    border: none;
    border-bottom: 1px solid #e4e7e9;
    -webkit-appearance: none;
    line-height: 24px;
    &:hover, &:focus {
      outline: 0;
    }
  }
  h5 {
    color: #131313;
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 32px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
  }
  .login-demo-server {
    text-align: center;
    a {
      color: $color-primary;
    }
  }
}


.back-to-login {
  height: 64px;
  border-top: 1px solid #e3e7eb;
  margin-top: 32px;
  padding: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  button {
    background: transparent;
    border: none;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: #337cbd;
    line-height: 1.71;
    letter-spacing: normal;
  }
  
}