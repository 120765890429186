
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

vp-contact-floating-window{
    .floating_window{
      box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.5);
      background: #e4edf5;
      position: fixed;
      bottom: 24px;
      display: flex;
      justify-content: space-between;
      height: 24px;
      padding: 13px;
      width: calc(100% - 21%);
      display: flex;
      align-items: center;
      @media only screen and (min-width : $layout-breakpoint-sm) and (max-width : $layout-breakpoint-md) and (orientation : landscape) {
        width: calc(100% - 25%);
        margin: 0 0 0 8%;
      }
      margin: 0 0 0 7%;
      z-index: 1;
      @media (max-width: 1023px) {
           display: none
     }
      &.sidebarExpanded {
        width: calc(100% - 42%);
        margin: 0 0 0 6%;
        @media only screen and (min-width : $layout-breakpoint-sm) and (max-width : $layout-breakpoint-md) and (orientation : landscape) {
            width: calc(100% - 52%);
            margin: 0 0 0 8%;
        }
      }
        .desktop_view , .mobile_view{
            display: block;
            .material-icons{
                font-size: 18px;
                color: #8f8f8f;
                margin-right: 13px;
                cursor: pointer;
                vertical-align: baseline;
                &.close{
                    color:#010101;
                }
            }
        }
        .mobile_view{
            display: none;
        }
        .select_count{
          font-size: 14px;
          color:#010101;
          text-align: left;
          margin: 0 0 0 18px;
        }
        .select_count_string {
          margin-left: 10px;
        }
        @media (max-width: $layout-breakpoint-sm) {
            .desktop_view{
                display: none;
            }
            .mobile_view{
                display: block;
                .close{
                    vertical-align: top;
                    margin-top: 6px;
                }
            }
        }
    }
}

vp-contact-mobile-floating-window{
  .floating_window{
    box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.5);
    background: $commander-standard-color;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 51px;
    padding: 0 0 0 9px;
    z-index: 1;
    margin: 0;
    width: 100%;
    top: 0em;
    color: white;
    z-index: 9;
    border-right: none;
    @media (min-width: 1024px) {
      display: none;
    }

    .checkbox-container {
        padding-left: 47px;
    }
        .mobile_view{
          display: block;
          .material-icons{
              font-size: 20px;
              color:#888888;;
              margin-right: 22px;
              cursor: pointer;
              vertical-align: baseline;
              &.close{
              }
          }
      }
      .left-container {
        display: flex;
      }
      .select_count{
        font-size: 17px;
        text-align: left;
        padding-left: 11px;
        padding-top: 1px;
        font-weight: 700;
      }
      .select_count_string {
        margin-left: 10px;
      }
      @media (max-width: $layout-breakpoint-sm) {
          .desktop_view{
              display: none;
          }
          .mobile_view{
              display: block;
              .close{
                margin-top: 1px;
              }
          }
      }
  }
  .mat-checkbox-frame {
    border: 1px solid white;
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #29b6f6;
  }
}

