
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

vp-vnc-contact-plus-list-layout{
    .summary-pane {
      border-right: 1px solid #cdcdcd;
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
    }
  
    .scrollable-content {
      margin: 0;
    }
  
    .contact-list-height {
      height: calc(100% - 30px);
      overflow-y: hidden;
      overflow-x: hidden;
      &.searchmode {
        height: calc(100% - 96px);
      }
      &.floting-visible {
        height: calc(100% - 132px);
        @media (max-width: 1023px) {
          height: calc(100% - 52px);
        }
      }
      &.serach-and-floting-visible {
        height: calc(100% - 180px);
      }
      &.search-panel-visible {
        height: calc(100% - 5px);
      }
    }
  
    .row-background {
      z-index: 1;
    }
  
    .scroll-bar {
      -webkit-overflow-scrolling: inherit;
      overflow-y: auto;
      position: relative;
      margin-top: 2px;
    }
  
    .list-items__button-bar {
      height: 0;
      opacity: 0;
      color: #999;
      background: #f8f8f8;
      .flex-100 {
        height: 100%;
      }
      .items-actions-spacing {
        justify-content: space-around;
        display: flex;
        width: 100%;
        padding-bottom: 5px;
      }
      .mark-all {
        margin-left: 15px;
        color: #979797;
        min-width: 45px;
        max-width: 45px;
        .mat-checkbox {
          padding: 0px;
          max-width: 14px;
        }
        .mat-button {
          padding: 0px;
          min-width: 12px;
        }
      }
      .header-panel {
        .mat-button {
          padding-left: 8px;
          padding-right: 8px;
          color: #565656;
          .material-icons {
            font-size: 20px;
          }
        }
        .toolbar-text {
          color: #565656;
          font-size: 12px;
          @media #{$xs-and-down} {
            display: none;
          }
        }
      }
      .mat-button:hover {
          background-color: #f8f8f8;
      }
    }
  
    .show-class {
      transition: height .5s,opacity .5s;
      opacity: 1;
      height: 45px;
    }
  
    .hide-class {
      transition: height .5s,opacity .5s;
      opacity: 0;
      height: 0;
    }
  
    .list-row {
      cursor: pointer;
      padding: 10px 0;
      color: #575656;
      background: #fff;
      border-bottom: 1px solid #dbdbdb;
    }
  
    .no-result {
      text-align: center;
      color: #3c3c3c;
      cursor: default;
    }
  
    .drag-transit {
      border: rgb(108, 87, 194) solid 2px;
    }
    .search-section {
      display: flex;
      justify-content: space-around;
      padding-top: 12px;
      border-bottom: solid 1px #cdcdcd;
      height: 45px;
      .material-icons {
        font-size: 20px;
        color: #565656;
      }
      .mat-button {
        color: #2A90B3;
      }
      .search-action-items {
        margin-top: -6px;
        display: flex;
      }
      .refine-button-position {
        margin-top: -2px;
      }
    }
    .star-unstar-lable {
      margin: 12px 0 0 16px;
      font-size: 14px;
      color: #707070;
      font-family: $manrop-font-family;
    }
    .contact-mobile-list-item {
      height: 58px;
      &.is-ios {
        height: 63px;
      }
    }
    .alphabet-holder {
      // width: 24px;
      // padding-top: 30px;
      // padding-right: 30px;
      // padding-left: 10px;
      visibility: hidden;
      width: 0px;
    .alphabet-title {
      // font-size: 20px;
      // font-weight: bold;
      // font-style: normal;
      // font-stretch: normal;
      // line-height: 0.63;
      // letter-spacing: normal;
      // text-align: center;
      // color:$commander-standard-color;
      // span {
      //   font-family: $manrop-font-family;
      // }
    }
    @media(max-width: $layout-breakpoint-sm) {
      // width: 3%;
      // padding-left: 12px;
    }
    }
  
    .contact-item-zise {
      width: 94%;
      margin-bottom: 2px;
      @media(max-width: 1023px) {
        width: 85%;
      }
    }
    .list-header-contact-sorting {
      display: flex;
      justify-content: space-between;
      height: 38px;
      background: #f6f6f6;
      border-bottom: 1px solid #e1e1e1;
      align-items: center;
      padding: 0 15px 0 15px;
      .left {
        font-family: $sans-pro-font-family;
        font-size: 12px;
        width: 60%;
        display: flex;
        .list-tag-name {
          max-width: 58%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          vertical-align: middle;
        }
        .selection-arrow {
          .mat-icon {
            font-size: 14px;
            color: #888888;
            display: inline;
            margin: 0 0 0 10px;
          }
        }
      }
      .right {
        font-family: $sans-pro-font-family;
        font-size: 12px;
        text-align: right;
        width: 40%;
        .mat-form-field {
          width: 70%;
          .mat-form-field-wrapper {
            padding-bottom: 0.75em;
            .mat-form-field-underline {
                display: none;
            }
          }
        }
      }
    }
    .alphabet-to-scroll {
      position: absolute;
      top: 1em;
      right: 10px;
      height: calc(100vh - 204px);
      display: grid;
      text-align: center;
      div {
        font-size: $small-font-size;
        font-family: $sans-pro-font-family;
        color: #888888;
        &.selected-item {
          color: $mobile-scroll-alphabet-color;
          font-weight: bold;
        }
        &.is-disabled {
          opacity: 0.3;
        }
      }
      .show-current-alphabet {
        position: absolute;
        right: 17px;
        height: 43px;
        width: 43px;
        border-radius: 6px;
        background: #f2f2f2;
        color: $mobile-scroll-alphabet-color;
        font-size: 32px;
        text-align: center;
        margin: -30px 0 0 0;
        .scroll-star-icon {
          font-size: 32px;
          vertical-align: initial;
          margin: 4px 0 0 -7px;
        }
      }
      .scroll-display-icon-star-item {
        font-size: 12px;
        height: 12px;
        width: 12px;
      }
    }
    .empty-wrapper {
      width: 100%;
      height: calc(100vh - 80px);
      @media #{$xs-and-down} {
        height: calc(100vh - 208px);
      }
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 14px;
      color: #b2b2b2;
      display: grid;
      align-items: center;
      align-content: center;
      text-align: center;
      font-family: $sans-pro-font-family !important;
    }
    .nocontact-container {
      .add_a_contact{
        color:$theme-color;
        cursor: pointer;
        margin-top: 10%;
        font-family: $manrop-font-family;
        font-weight: 700;
      }

      .no-contacts {
        color: #000000;
        font-weight: 500;
      }

      mat-icon {
        font-size: 75px;
        width: 75px;
        color:#cecece;
      }
      @media(max-width: $layout-breakpoint-sm) {
        mat-icon {
          font-size: 80px;
        }
      }
    }
    .contact_list_header{
      display: flex;
      .mat-menu-panel {
        box-shadow: 0px 1px 13px 0 rgba(0, 0, 0, .5);
        border-radius: 2.6px;
        max-height: calc(100vh - 240px);
      }
      @media(max-width: 1023px) {
        height: 45px;
        background-color: #f6f6f6;
        box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.5);
      }
      .contact-list-title{
        font-size: 14px;
        padding-left: 29px;
        @media(max-width: 1023px) {
          padding-left: 11px;
        }
        .header-desk-visbility {
          @media(max-width: 590px) {
            display: none
          }
        }
  
        .header-mobile-visbility {
          @media(min-width: 591px) {
            display: none
          }
        }
        .material-icons {
          color:rgba(0,0,0,.54);
          padding-left: 4px;
          padding-top: 10px;
        }
        span {
          padding-top: 12px;
        }
        display: flex;
        width: 65%;
        color: #6f6f6f;
        font-family: Roboto;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
      }
      .sort-options {
        width: 50%;
        text-align: right;
        .mat-form-field-infix {
          padding-top: 3px;
        }
        .sort_by{
          color:#6f6f6f;
          @media(max-width: $layout-breakpoint-sm) {
            display: none;
          }
        }
    }
    .refresh_contact_list_icon{
      width: 15px;
      font-size: 18px;
      vertical-align: middle;
      margin-right: 4%;
      cursor: pointer;
      @media #{$xs-and-down}, #{$sm-and-down} {
        margin-right: 0;
      }
     }
    }
  }
  li.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
  
  .show-sidebar {
    display: none;
    @media #{$xs-and-down}, #{$sm-and-down} {
      display: block;
      .material-icons {
        color: #0079a6;
      }
    }
  }
  
  