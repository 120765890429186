
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact-group-dialog {
    @media #{$xs-and-down} {
        height: 100%;
        width: 100%;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .content {
        margin-top: 10px;
        .contact-group-item {
            display: flex;
            align-items: center;
            height: 56px;
            margin-left: 10px;
            .full-name {
                margin: 0 0 0 15px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            vp-avatar {
                .mat-icon {
                    vertical-align: middle;
                    color: #fff;
                    font-size: 20px;
                }
            }
            .checked-contact-item {
                display: inline-block;
                border-radius: 50px;
                color: #fff;
                min-width: 38px;
                width: 38px;
                height: 38px;
                line-height: 38px;
                background: $action-menu-color;
                text-align: center;
            }
        }
    }
    .search-header {
        background: white;
        height: 51px;
        box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.5);
        .mat-icon {
            color: #000000;
        }
        .mat-form-field {
            width: 100%;
            color: #000000;
            .mat-form-field-wrapper {
                .mat-form-field-underline {
                    display: none;
                }
            }
            &.mat-focused {
                .mat-form-field-label {
                    color: rgba(0, 0, 0, 0.54);
                }
            }
        }
    }

    .vp-contact-mobile-group-floating-window{
        &.floating_window{
          box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.5);
          background: $commander-standard-color;
          position: fixed;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 51px;
          padding: 0 0 0 9px;
          z-index: 1;
          margin: 0;
          width: 100%;
          top: 0em;
          color: white;
          z-index: 9;
          border-right: none;
          @media (min-width: 1024px) {
            display: none;
          }
      
          .checkbox-container {
              padding-left: 47px;
          }
              .mobile_view{
                display: block;
                .material-icons{
                    font-size: 20px;
                    color:#888888;;
                    margin-right: 22px;
                    cursor: pointer;
                    vertical-align: baseline;
                    &.close{
                    }
                }
            }
            .left-container {
              display: flex;
            }
            .select_count{
              font-size: 17px;
              text-align: left;
              padding-left: 11px;
              padding-top: 1px;
              font-weight: 700;
            }
            .select_count_string {
              margin-left: 10px;
            }
            @media (max-width: $layout-breakpoint-sm) {
                .desktop_view{
                    display: none;
                }
                .mobile_view{
                    display: block;
                    .close{
                      margin-top: 1px;
                    }
                }
            }
        }
        .mat-checkbox-frame {
          border: 1px solid white;
        }
      }
}