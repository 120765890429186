
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact {
    &__dialog {
        .mat-dialog-container {
            padding: 0;
            overflow: hidden;
            @media #{$sm-and-up} {
                border-radius: 8px;
                box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
            }
        }

        @media #{$xs-and-down} {
            width: 100%;
            height: 100%;
        }

        position: absolute;
        width: 450px;
        max-width: 100%;
        max-height: 100%;
        min-height: 0px;
        overflow: initial;
        border-radius: 10px;
        background: $background-color;
        z-index: 2;

        &-header {
            justify-content: left;
            text-align: center;
            position: relative;
            z-index: 3;
            width: 100%;
            align-items: center;
            position: relative;
            padding: 10px 16px;
            display: flex;
            height: 52px;
            box-sizing: border-box;
            justify-content: space-between;
            border-bottom: 1px solid #e3e7eb;
            @media #{$xs-and-down} {
                background: $primary-color;
                color: $font-color-white;
                border-bottom: none;
                justify-content: end;
            }

            .mat-button {
                height: 24px;
                width: 24px;
                border-radius: 12px;
                min-width: 24px;
                line-height: 0;
                padding: 0;
                mat-icon {
                    color: #8b96a0;
                    &:hover {
                        color: #606B76;
                    }
                }
            }

            .header_lbl {
                display: flex;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: $dialog_header_title_font;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                color: #000;
                width: 100%;
                @media #{$xs-and-down} {
                    color: #fff;
                    font-size: 18px;
                    margin-left: 14px;
                }
            }

            .icon-text {
                display: flex;
                align-items: center;
            }

            .mobile-back-button {
                @media #{$sm-and-up} {
                    display: none;
                }
                margin: 0 5px 0 5px;
            }

            .desktop-close-button {
                margin: 0;
                display: flex;
                @media #{$xs-and-down} {
                  display: none;
                }
            }

            .desktop-close {
                width: 20%;
                justify-content: right;

                .mat-button {
                    float: right;
                }
            }

            .mobile-right-close {
                width: 20%;
                justify-content: right;

                .mat-button {
                    float: right;

                    .mat-icon {
                        font-size: 20px;
                        height: 20px;
                        width: 20px;
                    }

                    padding-right: 16px;
                }
            }
        }

        &-body {
            height: calc(100% - 55px);
            padding: 0;
            overflow: auto;
            font-size: $regular-font-size;
            @media #{$xs-and-down} {
                height: calc(100vh - 55px);
            }
            &.folder-body {
                height: 100%;
            }

            .non-decoration {
                text-decoration: blink;
                display: flex;
                align-items: center;
                color: #000000;
                width: 100%;
                height: 100%;
                padding-left: 25px;
            }

            .mat-list-item {
                cursor: pointer;

                .mat-icon {
                    height: 18px;
                    width: 18px;
                    font-size: 18px;
                }
            }

            .folder-icon {
                .mat-icon {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            .folder-title {
                padding-left: 23px;
                width: 75%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .folder-expander {
                width: 20%;

                .mat-icon {
                    float: right;
                    cursor: pointer;
                }
            }
        }

        &-footer {
            height: 52px;
            border-top: 1px solid #e3e7eb;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            box-sizing: border-box;
            @media #{$xs-and-down} {
                height: 44px;
            }

            .desktop-footer {
                @media #{$xs-and-down} {
                    display: none;
                }
                display: flex;
                padding: 0 16px;
                button {
                    cursor: pointer;
                    color: #317bbc;
                    font-size: 16px;
                    min-width: 73px;
                    height: 32px;
                    padding: 0 12px 0 13px;
                    border-radius: 4px;
                    border: solid 1px #8b96a0;
                    font-family: Manrope;
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.67;
                    text-transform: uppercase;
                    letter-spacing: normal;
                    text-align: center;
                    color: #606b76;
                    margin-left: 12px;
                    &.primary-button {
                        border: solid 1px transparent;
                        box-shadow: 0 2px 4px 0 rgba(7, 29, 51, 0.2), 0 0 2px 0 rgba(7, 29, 51, 0.1);
                        background-color: #337cbd;
                        color: #fff;
                        &:hover {
                            background-color: #388eda;
                            color: white;
                        }
                    }
                    &:hover {
                        color: #388eda;
                        border: solid 1px #388eda;
                        background-color: transparent;
                    }
                    &.disabled {
                        color: #888;
                    }
                }
            }

            .mobile-footer {
                @media #{$sm-and-up} {
                    display: none;
                }
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: 34px;
                margin: 10px 0 0 0;
                align-items: flex-end;
                position: static;
                bottom: 0;
                background: #0071bb;
                align-items: center;
                .title {
                    padding-left: 25px;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 400;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.29;
                    letter-spacing: normal;
                    text-align: left;
                    color: #fff;
                }
                .save_footer_btn {
                    display: block;
                    button {
                        width: 57px;
                        height: 57px;
                        min-width: 51px;
                        right: 10px;
                        bottom: 19px;
                        border-radius: 50%;
                        box-shadow: 0 1px 3px 0 rgba(0,0,0,.5);
                        background-color: #29b6f6;
                        .mat-icon {
                            color: #fff;
                            font-size: 30px;
                            margin: 0 0 7px -4px;
                        }
                    }
                }
            }
        }
    }
}
.mat-input-element {
    caret-color: #337cbd!important;
}
.mat-form-field.mat-focused .mat-form-field-label {
    color: #337cbd!important;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #337cbd!important;
}

.add-list-mobile-footer-btn{
    background-color: transparent !important;
}