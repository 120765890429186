
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.create_contactgroup_dialog {
    height: 535px;
    width: 600px;
    @media #{$xs-and-down} {
        height: 100%;
        width: 100%;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .contact__dialog-body {
        &.is-contact-select-item {
            @media #{$sm-and-down} {
                height: calc(100vh - 92px);
            }
        }
        &.is-search-view-open {
            height: calc(100vh - 55px);
        }
    }
    .desktop-close-button {
        .mat-icon {
            font-size: 20px;
            color: #888888;
        }
    }
    .search-header {
        background: white;
        height: 51px;
        box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.5);
        .mat-icon {
            color: #000000;
        }
        .mat-form-field {
            width: 100%;
            color: #000000;
            .mat-form-field-wrapper {
                .mat-form-field-underline {
                    display: none;
                }
            }
            &.mat-focused {
                .mat-form-field-label {
                    color: rgba(0, 0, 0, 0.54);
                }
            }
        }
    }
    .content {
        .app-disabled {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 30px;
            left: 0px;
            z-index: 10000000001;
            padding: 80px 0px;
            text-align: center;
            .sk-fading-circle {
                margin: 200px auto;
            }
        }
    }
    .main {
        .group-name {
            display: flex;
            align-items: center;
            padding: 0 34px;
            @media #{$xs-and-down} {
                margin: 0 0 0 9px;
            }
            &.is-search-view-open {
                display: none;
            }
            .icon {
                width: 10%;
                @media #{$xs-and-down} {
                    width: 15%;
                }
                .icon-div {
                    background: #eaeaea;
                    border-radius: 50%;
                    height: 48px;
                    width: 48px;
                    align-items: center;
                    display: flex;
                    .mat-icon {
                        vertical-align: text-bottom;
                        margin: 0 0 0 3px;
                        color: #a8a8a8;
                    }
                    vp-avatar {
                        .avatar {
                            &.list-avatar {
                                border-radius: 50%;
                                overflow: hidden;
                                height: 48px;
                                width: 48px;
                                line-height: 48px;
                                font-size: 20px;
                                text-align: center;
                                display: inline-block;
                                color: white;
                                background-color: #ededed;
                                cursor: pointer;
                                @media #{$xs-and-down} {
                                    height: 54px;
                                    width: 54px;
                                    line-height: 54px;
                                }
                            }
                        }
                    }
                }
            }
            .name {
                width: 90%;
                margin: 10px 0 0 0;
                @media #{$xs-and-down} {
                    width: 85%;
                    margin: 10px 0 0 10px;
                }
                .mat-form-field {
                    width: 100%;
                    padding-left: 10px;
                }
            }
        }
        .search-part {
            display: flex;
            align-items: center;
            padding: 0 34px;
            &.is-search-view-open {
                display: none;
            }
            .search {
                width: 65%;
                @media #{$xs-and-down} {
                    width: 40%;
                    display: none;
                }
                .mat-form-field {
                    width: 85%;
                }
                .mat-icon {
                    font-size: 20px;
                    color: #888888;
                    vertical-align: top;
                    cursor: pointer;
                }
            }
            .dropdown {
                width: 35%;
                @media #{$xs-and-down} {
                    width: 100%;
                    margin: 0 6px 0 18px;
                    .mat-form-field {
                        width: 98%;
                    }
                }
            }
        }
        .contact-items {
            .mat-list {
                height: 282px;
                overflow: auto;
                &.desktop-list {
                    display: block;
                    @media #{$xs-and-down} {
                        display: none;
                    }
                    .mat-list-item-content {
                        margin: 4px 0px;
                        padding: 0 34px;
                    }
                }
                &.mobile-list {
                    display: none;
                    @media #{$xs-and-down} {
                        display: block;
                        height: calc(100vh - 205px);
                    }
                    &.is-search-view-open {
                        height: calc(100vh - 63px);
                    }
                    .mat-list-item-content {
                        margin: 8px 0px;
                    }
                }
                &.is-contact-select {
                    height: 200px;
                    &.desktop-list {
                        display: block;
                        @media #{$xs-and-down} {
                            display: none;
                        }
                    }
                    &.mobile-list {
                        display: none;
                        @media #{$xs-and-down} {
                            display: block;
                            height: calc(100vh - 324px);
                        }
                        &.is-search-view-open {
                            height: calc(100vh - 63px);
                        }
                    }
                }
                .mat-list-item {
                    .user-avatar {
                        vp-avatar {
                            .list-avatar {
                                height: 36px;
                                width: 36px;
                                line-height: 36px;
                                font-size: 16px;
                                @media #{$xs-and-down} {
                                    height: 42px;
                                    width: 42px;
                                    line-height: 42px;
                                    font-size: 24px;
                                }
                            }
                        }
                    }
                    .fullname {
                        font-family: $sans-pro-font-family;
                        margin: 0 10px 0 25px;
                    }
                    .email-address {
                        color: #888888;
                        font-family: $sans-pro-font-family;
                    }
                    .all-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        .name-avatar {
                            display: flex;
                            align-items: center;
                        }
                        .info-item-icon {
                            color: #888888;
                        }
                    }
                }
            }
        }
        .all-selected-item {
            display: flex;
            overflow: auto;
            width: 522px;
            @media #{$xs-and-down} {
                width: 92%;
                margin: 0 auto;
            }
            margin: 0 0 0 30px;
            height: 80px;
            .selected-contact-item {
                position: relative;
                margin: 0 40px 0 0;
                text-align: center;
                vp-avatar {
                    .list-avatar {
                        height: 36px;
                        width: 36px;
                        line-height: 36px;
                        font-size: 16px;
                        @media #{$xs-and-down} {
                            height: 42px;
                            width: 42px;
                            line-height: 42px;
                            font-size: 24px;
                        }
                    }
                }
                .name {
                    font-size: 12px;
                    font-family: $sans-pro-font-family;
                }
                .close-icon {
                    background: #888888;
                    width: 16px;
                    border-radius: 50%;
                    height: 16px;
                    position: absolute;
                    left: 24px;
                    top: 25px;
                    border: 1px solid $white-color;
                    @media #{$xs-and-down} {
                        left: 30px;
                        top: 28px;
                    }
                    .mat-icon {
                        color: $white-color;
                        vertical-align: super;
                        margin: 1px 0 0 1px;
                        cursor: pointer;
                    }
                }
            }
            &.is-search-view-open {
                display: none;
            }
        }
    }
    .contact__dialog-footer {
        @media #{$xs-and-down} {
            border: none;
        }
        &.is-search-view-open {
            display: none;
        }
    }
}
.contact-group-drag-preview {
    background: rgba(255,255,255,0.7);
    padding: 15px 28px;
    color: #575656;
    border: 1px solid #e1e1e1;
    cursor: pointer;
    font-size:14px;
    font-weight:800;
}