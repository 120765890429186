
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.add_to_tags_dialog {
    width: 90%;
    height: 93%;
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .mobile-back-button {
        margin: 0 5px 0 5px;
    }
    .contact__dialog-header-item {
        margin: 20px 20px 0 20px;
        .header_lbl {
            &.text {
                font-size: $regular-font-size;
                font-weight: bold;
                font-family: $manrop-font-family;
            }
            .mat-form-field {
                width: 100%;
            }
            .mat-icon {
                font-size: $regular-font-size;
                color: #888888;
            }
        }
    }
    .contact__dialog-body {
        height: calc(100vh - 260px);
        @media #{$sm-only} {
            height: calc(100vh - 283px);
        }
    }
    .content {
        padding: 0 30px 0 18px;
        .folder-list-item {
            display: flex;
            justify-content: space-between;
            height: 48px;
            align-items: center;
            .name {
                .mat-icon {
                    margin: 0 20px 0 0;
                }
                white-space: nowrap;
                max-width: 80%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: $regular-font-size;
                font-family: $sans-pro-font-family;
            }
            .count {
                font-size: $medium-font-size;
                color: #888888;
                font-family: $sans-pro-font-family;
            }
        }
    }
    .create-new-list {
        margin: 15px 0 11px 16px;
        color: $list-color-item;
        .text {
            font-size: $regular-font-size;
            font-family: $sans-pro-font-family;
            margin: 0 0 0 20px;
        }
        .new-list-span {
            display: flex;
            align-items: center;
        }
    }
    .footer-item {
        text-align: right;
        color: $list-color-item;
        button {
            font-family: $manrop-font-family;
            font-size: $medium-font-size;
        }
    }
}