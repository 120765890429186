
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact-help-faq-dialog {
    @media #{$xs-and-down} {
        height: 100%;
        width: 100%;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
    }
    .content {
        padding: 15px;
        h6 {
            font-size: 1.14rem;
            font-weight: 700;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.25;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            margin: 10px 0 0;
        }
        a {
            font-weight: 400;
            color: #317bbc;
            text-decoration: none;
            outline: none;
            word-break: break-word;
            font-size: 14px;
        }
    }
}