
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

 vp-trash-contact-component {
	.list-tag-header {
		display: flex;
		align-items: center;
		height: 38px;
		width: 70%;
		border-radius: 6px;
  		border: solid 1px #e0e0e0;
		background-color: #f2f2f2;
		margin: 10px 0 5px 31px;
		font-family: $manrop-font-family;
		font-size: 14px;
		color: #707070;
		.type {
			margin: 0 7px 0 10px;
		}
		.name-count {
			margin: 0 5px 0 5px;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 100px;
			overflow: hidden;
			cursor: pointer;
		}
	}
	.list-view {
		margin-left: 20px;
	}
	.main-list-division {
		display: flex;
	}
	.table-division-on-select {
		max-width: 340px;
	}
	.mat-table {
		overflow-y: auto;
		overflow-x: hidden;
		height: calc(100vh - 65px);
		&.is-tag-list-header {
			height: calc(100vh - 120px);
		}
		&.is-multiple-contact-select {
			width: 340px;
		}
	}
	mwlResizable {
		box-sizing: border-box;
	}
	mat-cell,
	mat-footer-cell,
	mat-header-cell {
		width: calc( 50% - 32px);
		word-break: break-all;
		flex: none;
		display: block;
		&.widthAuto {
			width: auto;
		}
	}
	mat-header-cell {
		border-left: 1px solid #e0e0e0;
		&.operation-header {
			width: 100%;
		}
	}
	.mat-header-cell {
		padding-left: 12px;
		padding-right: 12px;
		line-height: 30px;
		font-size: $medium-font-size;
		color:#707070;
		font-family: $manrop-font-family;
		font-weight: normal;
		min-height: auto;
		.check-item {
			margin: 0 13px 0 9px;
		}
		.option-name {
			font-size: 14px;
			font-weight: bold;
			letter-spacing: normal;
			text-align: left;
			color: #317bbc;
			margin-left: 25px;
			cursor: pointer;
		}
		.mat-checkbox {
			.mat-checkbox-layout {
				.mat-checkbox-inner-container {
					.mat-checkbox-frame {
						border-color: #888888;
					}
				}
			}
		}
		span {
			font-family: $manrop-font-family;
		}
	}
	.mat-sort-header-button {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.mat-cell {
		padding-left: 12px;
		padding-right: 12px;
       // border-left: 1px solid #eee;
        vp-avatar {
            .list-avatar {
                height: 36px;
                width: 36px;
                line-height: 36px;
                font-weight: normal;
								font-size: 16px;
								vertical-align: middle;
            }
		}
		.checkbox-span-selected {
			margin: 0 10px 0 -11px;
		}
    }
    .mat-row {
		border-style: none;
		cursor: pointer;
		height: 60px;
		&.is-select-row {
			background: $list-selection-color;
		}
		&:hover {
			background: $list-hover-color;
		}
    }
	.mat-cell {
		display: flex;
		vertical-align: middle;
		line-height: 3em;
		white-space: nowrap;
		align-items: center;
		overflow: hidden;
        text-overflow: ellipsis;
		span {
			font-family: $manrop-font-family;
		}
		
		.full-name {
            font-weight: bold;
			display: flex;
			font-family: $manrop-font-family;
			display: flex;
			align-items: center;
			.contact_name {
				padding-left: 25px;
				max-width: 200px;
			}
		}

		.list-name {
            width: 100%;
            display: flex;
            .name  {
                width: 80%;
            }
			.ops-item {
                width: 20%;
				display: flex;
				justify-content: flex-end;
                align-items: center;
                span {
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: normal;
                    text-align: left;
                    color: #317bbc;
                }
			}
		}
		.mat-icon {
			margin: 0 20px 0 0;
			color: #888888;
		}
    }
    .mat-header-row {
		border-color: #e1e1e1;
		background: $white-color;
		font-weight: normal;
	}
	mat-header-row {
		min-height: 45px;
	}
	.mobile-view-trash-contact {
		.delete-time-message {
			text-align: center;
			padding: 10px 20px 10px 20px;
			background: #f6f6f6;
			border-bottom: 1px solid #e0e0e0;
			.message {
				font-size: $medium-font-size;
				font-family: $manrop-font-family;
				color: #888888;
				line-height: 1.4;
			}
			.emptynow {
				font-size: $medium-font-size;
				font-family: $manrop-font-family;
				color: $active-link-color;
				font-weight: bold;
				margin: 6px 0 0 0;
			}
		}
		.content {
			margin-top: 10px;
			overflow: auto;
			height: calc(100vh - 210px);
			.contact-item {
				display: flex;
				align-items: center;
				height: 60px;
				margin-left: 10px;
				.full-name-delete-at {
					margin: 0 0 0 15px;
					.delete-at-item {
						font-size: $medium-font-size;
						color: #888888;
						font-family: $sans-pro-font-family;
					}
					.full-name {
						font-family: $sans-pro-font-family;
					}
				}
				vp-avatar {
					.mat-icon {
						vertical-align: middle;
						color: #fff;
						font-size: 20px;
					}
				}
				.checked-contact-item {
					display: inline-block;
					border-radius: 50px;
					color: #fff;
					min-width: 42px;
					width: 42px;
					height: 42px;
					line-height: 42px;
					background: $action-menu-color;
					text-align: center;
				}
			}
		}


		.vp-contact-mobile-floating-window{
			&.floating_window{
			  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.5);
			  background: $commander-standard-color;
			  position: fixed;
			  display: flex;
			  align-items: center;
			  justify-content: space-between;
			  height: 51px;
			  padding: 0 0 0 9px;
			  z-index: 1;
			  margin: 0;
			  width: 100%;
			  top: 0em;
			  color: white;
			  z-index: 9;
			  border-right: none;
			  @media (min-width: 1024px) {
				display: none;
			  }
		  
			  .checkbox-container {
				  padding-left: 47px;
			  }
				  .mobile_view{
					display: block;
					.mat-icon {
						margin: 0 30px 0 0;
					}
				}
				.left-container {
				  display: flex;
				}
				.select_count{
				  font-size: 17px;
				  text-align: left;
				  padding-left: 11px;
				  padding-top: 1px;
				  font-weight: 700;
				}
				.select_count_string {
				  margin-left: 10px;
				}
				@media (max-width: $layout-breakpoint-sm) {
					.desktop_view{
						display: none;
					}
					.mobile_view{
						display: block;
						.close{
						  margin-top: 1px;
						}
					}
				}
			}
			.mat-checkbox-frame {
			  border: 1px solid white;
			}
		  }
	}
}