
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

 $neutral_10: #FAFBFC;
 $neutral_20: #F7F8FA;
 $neutral_30: #F5F8FA;
 $neutral_100: #EAEEF2;
 $neutral_200: #E3E7EB;

 $neutral_300: #DADEE2;
 $neutral_400: #C5CBD1;
 $neutral_500: #A8B0B9;
 $neutral_600: #8B96A0;
 $neutral_700: #7A858F;
 $neutral_800: #606B76;
 $neutral_900: #525F6C;

 // Shadows Units
 $shadow_dropdown: 0 8px 16px 0 rgba(8, 45, 61, 0.2), 0 4px 12px 0 rgba(8, 45, 61, 0.1);

// Responsive breakpoints
$layout-breakpoint-xs: 767px !default;
$layout-breakpoint-sm: 960px !default;
$layout-breakpoint-gt-sm: 1024px !default;
$layout-breakpoint-md: 1280px !default;
$layout-breakpoint-lg: 1920px !default;
$mobile-breakpoint: 800px;

$brand-main: #009FDB;
$brand-light: #81D4FA;
$brand-lighter: #B3E5FC;
$brand-highlight: #EC7400;

$xs-and-down: 'only screen and (max-width : #{$layout-breakpoint-xs - 1})' !default;  // only xs screens (phone)
$sm-and-down: 'only screen and (max-width : #{$layout-breakpoint-sm - 1})' !default;  // sm and xs screens (phone and tablet)
$sm-and-up: 'only screen and (min-width : #{$layout-breakpoint-xs})' !default;  // sm, md and lg (tablet and desktop)
$sm-only: 'only screen and (min-width : #{$layout-breakpoint-xs}) and (max-width : #{$layout-breakpoint-sm - 1})' !default;  // only sm screens (tablet)
$md-only: 'only screen and (min-width : #{$layout-breakpoint-sm}) and (max-width : #{$layout-breakpoint-md - 1})' !default;  // only md screens (tablet-adapted)
$md-and-up: 'only screen and (min-width : #{$layout-breakpoint-sm})' !default;  // md and lg screens (desktop)
$md-and-down: 'only screen and (max-width : #{$layout-breakpoint-md - 1})' !default;  // xs, sm and md screens

$background-color: white;
$theme-color: #317bbc;
$primary: #317bbc!default;
$primary-color: #317bbc!default;
$link-color: #777;
$active-link-color: #317bbc;
$action-menu-color: #29b6f6;
$header-background-color: white;
$header-notification-count-color: #ff4300;
$header-border-color: #888888;
$header-color-gray: gray;
$font-color-white: white;
$font-color-black: #000000;
$regular-font-size: 16px;
$medium-font-size: 14px;
$large-font-size: 18px;
$small-font-size: 12px;
$extra-large-font-size: 24px;
$regular-font-family: Roboto;
$profile-background-color:  #ffc208;
$color-primary: rgb(0, 112, 188);
$dialog-header-background-color:#317bbc;
$selection-color: #e2e2e2;
$manrop-font-family: Manrope;
$sans-pro-font-family: Source Sans Pro;
$list-color-item: #0e71bb;
//Header
$header-height: 64px;
$list-hover-color: #e2e2e2;
$list-selection-color: #e2e2e2;

// Tabs


$primary-tab-color: #317bbc;
$gray-color: #888888;
$white-color: #fff;
$blue-color: #317bbc;
$primary-tab-link-bar-color: #317bbc;
$search-tab-view-background-color: #317bbc;

// Sidebar
$sidebar-width : 270px;
$sidebar-width-ipad : 200px;
$sidebar-background-color: #eee;
$sidebar-compose-icon-background-color: #29b6f6;
$sidebar-compose-icon-background-hover: #317bbc;

// Dialog button color
$dialog-operation-buttons-color: #0071bb;
$dialog-action-notifier-bottom-color: #0071bb;
$dialog-tabs-color: #0071bb;
$dialog-footer-background-color: #0071bb;
$dialog-save-button-background-color: #29b6f6;

//Textbox focused
$mobile-textbox-focused-color:#007dc3;

// Contacts
$brand-color:#0071bb !default;
$themeColor:#0071bb !default;
$brand-secondary-color: #0071bb !default;
$font-color-light: #ababab;
// Header
$header-background: $brand-color !default;
$header-pink-background: #B64983 !default;
$bread-background-color: #eee !default;
$bread-item-color: $brand-color;
$bread-arrow-color: #686868 !default;
// Sidebar drawer
$sidebar-font-color: #575757 !default;
$sidebar-drawer-background: #E5E5E5 !default;
$important-filter-badge-background: #F72908 !default;
$my-filter-badge-background: #1FAE92 !default;
$filter-background: #F0F0F0 !default;
$filter-background-current: #FAFAFA !default;
$filter-font-current: #505050 !default;
$brand-color:#0071bb !default;
$brand-secondary-color: #0071bb !default;
$link-color: #0071bb !default;
$font-color-light: #ababab;
$fields-border-bottom-color: #e0e0e0 !default;
$stream-box-border: #eae5e9 !default;
$commander-standard-color: #0071bb !default;
$unreaded-color: #ff4300 !default;
$grey-background: #f7f7f7 !default;
$black-font-color: rgba(0, 0, 0, .87) !default;
$white-background: #fff !default;
$white-font-color: #fff !default;
$grey-font-color: #ababab !default;
$dark-grey-font-color: #8f8f8f !default;
$grey-icon: #8f8f8f !default;
$darker-grey-icon: #7b7b7b !default;
$brand-button-color: #9f5881 !default;
$darker-grey-font-color: #4D4D4D !default;
$username-color: #3b3b3b !default;
$login-button-background: $primary !default;
$login-forgot-pass-color: $white-font-color !default;
$option-hover-background-color: #eee;
//Icons
$ic-folder: "data:image/svg+xml, %3Csvg fill='%23757575' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z'/%3E %3Cpath d='M0 0h24v24H0z' fill='none'/%3E %3C/svg%3E" !default;

//Buttons
$button-background: #5c5c5c !default;
$button-background-hover: $darker-grey-font-color !default;
$submit-button-background: $primary !default;
$submit-button-background-hover: #2c74b3 !default;


// App
$divider-border: #E8E3E7 !default;
$divider-on-brand-color: #1a86af !default;

$primary-font: 'Montserrat';
$preferred_font: 'Montserrat';
$standard-font: 'Montserrat';
$dialog_header_title_font: 'Manrope';
$field_font: 'SourceSansPro';

body::after {
    display: none;
    @media(min-width: $layout-breakpoint-md) {
      content: 'desktop';
    }
    @media(max-width: $layout-breakpoint-sm) {
      content: 'tablet';
    }
    @media(max-width: $layout-breakpoint-xs) {
      content: 'mobile';
    }
}

$mobile-textbox-focused-color: $brand-color;

$mobile-scroll-alphabet-color: #29b6f6;