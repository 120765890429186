
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact_about_dialog {
    height: 454px;
    width: 360px;
    background-size: cover;
    .mat-dialog-container {
        padding: 0;
    }
    @media(max-width: $layout-breakpoint-sm) and (orientation:landscape) {
        max-height: 90vh;
    }

    @media #{$xs-and-down} {
        width: 100%;
        height: 100%;
    }

    .desktop-close-button {
        position: absolute;
        right: 10px;
        display: block;

        .mat-button {
            color: #fff;
        }
    }

    .contact__dialog-header {
        border-bottom: none;
        background: none;
    }
    .about_dialog {
        height: 454px;
        @media #{$xs-and-down} {
            height: 100%;
        }
    }

    .content {
        padding: 40px 0 0 0;
        text-align: center;

        .change_log {
            width: 100%;
            padding-top: 95px;

            @media #{$xs-and-down} {
                position: absolute;
                bottom: 20px;
                display: block;
            }

            a {
                text-decoration: none;
                color: $active-link-color;
            }
        }

        .contact_icon {
            height: 110px;
            width: 110px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.27);
            border-radius: 50%;
            @media #{$xs-and-down} {
              height: 125px;
              width: 125px; 
            }
        }

        .app_verion_name {
            color: #000;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.31;
            letter-spacing: normal;
            text-align: center;
            margin-bottom: 0;
            margin-top: 15px;
            font-size: 24px;

            span {
                font-weight: 600;
                font-size: 36px;

                &.dfb_text {
                    font-weight: 600;
                }
            }

            .product_name {
                font-weight: 300;
            }
            .logo-title {
                border-left:none;
                display: inline-flex;
                @media #{$xs-and-down} {
                    color: #fff;
                }
            }
        }

        .app_version {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.87;
            letter-spacing: normal;
            color: #6b6b6b;
        }
    }
}