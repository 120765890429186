
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

vp-contact-plus-list-component {
	.search-header-with-count {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 15px 10px 15px 20px;
		.text {
			font-family: $manrop-font-family;
			font-size: 14px;
			color: #888888;
			display: flex;
			.save-search-result {
				display: flex;
				align-items: center;
				.refine-search {
					font-family: $manrop-font-family;
					font-size: $medium-font-size;
					font-weight: bold;
					color: $theme-color;
					margin: 0 0 0 25px;
					cursor: pointer;
				}
				.save-search {
					font-family: $manrop-font-family;
					font-size: $medium-font-size;
					font-weight: bold;
					color: $theme-color;
					margin: 0 0 0 25px;
					cursor: pointer;
				}
				.clear-search {
					font-family: $manrop-font-family;
					font-size: $medium-font-size;
					font-weight: bold;
					color: $theme-color;
					margin: 0 0 0 25px;
					cursor: pointer;
				}
			}
		}
		.mat-icon {
			&.close-search-icon {
				color: #888888;
				cursor: pointer;
			}
			&.save-icon {
				color: #888888;
				vertical-align: text-top;
			}
		}
		.save-search {
			cursor: pointer;
			margin: 0 15px 0 0;
			font-size: $medium-font-size;
			color: #888888;
			font-family: $sans-pro-font-family;
			vertical-align: bottom;
		}
	}
	.list-tag-header {
		display: flex;
		align-items: center;
		height: 38px;
		width: 70%;
		border-radius: 6px;
		background-color: #f2f2f2;
		margin: 10px 0 5px 31px;
		font-family: $manrop-font-family;
		font-size: 14px;
		color: #707070;
		.type {
			margin: 0 7px 0 10px;
		}
		.name-count {
			margin: 0 5px 0 5px;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 100px;
			overflow: hidden;
			cursor: pointer;
		}
	}
	.list-view {
		margin-left: 20px;
		overflow-y: auto;
		overflow-x: hidden;
		height: calc(100vh - 48px);
		&:has(.is-multiple-contact-select) {
			width: 340px;
			min-width: 340px;
			.cdk-virtual-scroll-viewport {
				overflow-x: hidden;
			}
		}
		&.loading-more-data {
			height: calc(100vh - 340px);
			.cdk-virtual-scroll-viewport {
				overflow: hidden;
			}
		}
	}
	.main-list-division {
		display: flex;
		@media screen and (max-width: 960px) and (min-width: 768px) {
			max-width: calc(100vw - 240px);
		}
	}
	.table-division-on-select {
		max-width: 340px;
		@media screen and (max-width: 1024px) {
			max-width: 280px;
			.cdk-table.is-multiple-contact-select {
				width: 280px;
			}
		}
	}
	.cdk-table {
		overflow-y: auto;
		overflow-x: hidden;
		// height: calc(100vh - 48px);
		border-collapse: collapse;
		width: 100%;
		&.is-tag-list-header {
			// height: calc(100vh - 120px);
		}
		&.is-multiple-contact-select {
			width: 340px;
		}
	}
	mwlResizable {
		box-sizing: border-box;
	}
	.cdk-cell,
	.cdk-footer-cell,
	.cdk-header-cell {
		width: calc( 25% - 28px);
		word-break: break-all;
		&.widthAuto {
			width: auto;
		}
	}
	.cdk-cell:last-of-type {
		padding-right: 0px;
	}
	.cdk-header-cell:last-of-type {
		padding-right: 0px;
	}
	.cdk-header-cell {
		line-height: 30px;
		font-size: $medium-font-size;
		color:#707070;
		font-family: $manrop-font-family;
		font-weight: normal;
		min-height: auto;
		width: 25%;
		.header-div-parent {
			height: 100%;
			width: 101%;
			border-bottom: 1px solid #e0e0e0;
			.header-div-child{
				height:30px;
				padding:6px 12px;
				border-left: 1px solid #e0e0e0;
				&.fullName-column {
					padding-left:24px;
				}
			}
		}
		.check-item {
			margin: 0 13px 0 9px;
		}
		.mat-checkbox {
			.mat-checkbox-layout {
				.mat-checkbox-inner-container {
					.mat-checkbox-frame {
						border-color: #888888;
					}
				}
			}
		}
		span {
			font-family: $manrop-font-family;
		}
		.header-hover-drag-icon {
			display: none;
		}
		&:hover {
			cursor: pointer;
			.header-hover-drag-icon {
				display: inline-block;
			}
		}

		.active-icon {
			color: #484646;
		}
		.non-active-icon {
			color: #b3b0b0;
		}
	}
	.mat-sort-header-button {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.cdk-cell {
		padding: 0 12px;
	   &.fullName-cell {
			&:not(.group-header-row) {
				padding-left: 24px;
			}
			&.contact-checked-hover {
				padding-left: 0;
			}
			&.group-header-row {
				padding: 0;
				.full-name {
					padding-right: 12px;
					padding-left: 24px;
					box-shadow: inset 0 -1px 0 0 #e3e7eb;
				}
				&:hover {
					.full-name {
						background: #f7f8fa;
					}
				}
				&.first-group-header {
					.full-name {
						margin-bottom: 8px;
					}
				}
				&:not(.first-group-header) {
					.full-name {
						margin-top: 16px;
						margin-bottom: 8px;
					}
				}
			}
	   }
        vp-avatar {
            .list-avatar {
                height: 36px;
                width: 36px;
                line-height: 36px;
                font-weight: normal;
				font-size: 16px;
				vertical-align: middle;
				font-family: 'Source Sans Pro';
				margin: 0;
            }
		}
		.select-checkbox-avatar {
			display: flex;
			.drag-handle {
				height: 24px;
				width: 24px;
				margin: 16px 0;
				svg {
					padding: 3px;
				}
			}
			.avatar-hover {
				display: flex;
				position: relative;
				.checkbox-block {
					position: absolute;
					left: 6px;
					top: 16px;
					z-index: 99;
					height: 24px;
					width: 24px;
					svg {
						padding: 4px;
					}
				}
			}
			.avatar-block {
				&.hover-active {
					.list-avatar {
						opacity: 0.8;
						background-color: #388eda!important;
						img {
							opacity: 0.4;
							background: #388eda !important;
						}
						.profile-avtar {
							visibility: hidden;
						}
					}
				}
			}
		}
    }
    .cdk-row {
		border-style: none;
		cursor: pointer;
		height: 56px;
		&:has(.group-header-item) {
			height: 48px;
		}
		&.is-select-row {
			background: $list-selection-color;
		}
		&:hover {
			.fullName-cell {
				&.contact-checked-hover {
					padding-left: 0;
				}
			}
		}
		&:has(.contact-checked-hover) {
			background: #f7f8fa;
		}
    }
	.cdk-cell {
		// display: flex;
		font-size: 14px;
		vertical-align: middle;
		line-height: 3em;
		white-space: nowrap;
		align-items: center;
		overflow: hidden;
		text-overflow: ellipsis;
		&:has(.full-name) {
			line-height: 1em;
		}
		span {
			font-family: $manrop-font-family;
		}
		.group-header-item {
			margin: 12px 0;
			display: flex;
			.header-label {
				font-family: $manrop-font-family;
				font-weight: normal;
				font-size: 16px;
				color: #000000;
				font-weight: bold;
				line-height: 23px;
			}
			.header-count {
				font-family: $manrop-font-family;
				font-weight: normal;
				font-size: 14px;
				color: #707070;
				line-height: 24px;
				margin: 0 4px 0 8px;
			}
			.toggle-collapse {
				height: 24px;
				width: 24px;
				display: inline-block;
				svg {
					padding: 4px;
					g g polygon {
						fill: #000000;
					}
				}
			}
		}

		&.contact-only-checked {
            display: none!important;
        }

		.full-name {
      		font-weight: bold;
			display: flex;
			font-family: $manrop-font-family;
			display: flex;
			align-items: center;
			.contact_name {
				padding-left: 16px;
				line-height: 24px;
				max-width: 200px;
			}
			.avatar-block {
				height: 36px;
				width: 36px;
				margin: 10px 0;
			}
		}

		.list-name {
			width: 100%;
			display: flex;
			.name  {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				width: 100%;
				&.more {
					width: 65%;
				}
			}
			.ops-item {
				width: 35%;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				.action-icons {
					display: inline;
					padding: 3px;
					height: 24px;
					width: 24px;
					margin-right: 8px;
					&:not(.remove-favourite) {
						svg path {
							fill: #8B96A0;
						}
						&:hover {
							svg path {
								fill: #606B76;
							}
						}
					}
				}
				.mat-icon {
					margin: 0 10px 0 10px;
					&.favorite-mark {
						color: #ffa800;
					}
				}
			}
		}
		.mat-icon {
			vertical-align: middle;
			font-size: 20px;
		}
		.email-item-column {
			max-width: 200px;
			display: block;
		}
		.phone-item-column {
			max-width: 200px;
			display: block;
		}
		.list-item-column {
			max-width: 200px;
			display: flex;
		}
    }
    .cdk-header-row {
		background: $white-color;
		font-weight: normal;
		&.header-table-class {
			.cdk-header-cell {
				background-color: #FFFFFF;
			}
		}
	}
	.cdk-header-row {
		min-height: 45px;
		height: 45px;
		.cdk-header-cell {
			min-height: 45px;
			height: 45px;
			width: 25%;
		}
	}

	.contact-desktop-alphabet-list {
		position: absolute;
		right: 10px;
		top: 66px;
		// display: grid;
		// height: calc(100vh - 145px);
		div {
			display: none;
			&.show-toast-alphabet {
				display: block;
				height: 33px;
				width: 33px;
				font-weight: bold;
				font-family: $manrop-font-family;
				background: #317bbc;
				color: white;
				font-size: 20px;
				z-index: 99999;
				text-align: center;
				position: absolute;
				right: 9px;
				.mat-icon {
					font-size: 20px;
					color: #FFFFFF;
					height: 20px;
					width: 20px;
					margin: 6px 0 0 0;
				}
			}
		}
	}
}
.table-drag-preview-item {
	background: $white-color;
	height: 40px;
	width: 200px;
	-moz-box-shadow: 0px 2px 4px 0 #8c8c8c;
    -webkit-box-shadow: 0px 2px 4px 0 #8c8c8c;
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.27);
	span {
		margin-left: 20px;
		padding-top: 10px;
		display: inline-block;
		color: #707070;
		font-family: $manrop-font-family;
	}
}