
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact_tfa_settings_dialog {
  @media #{$xs-and-down} {
    height: 100%;
    width: 100%;
  }
  .mat-dialog-container {
    padding: 0;
    overflow: hidden;
    @media #{$sm-and-up} {
        border-radius: 8px;
        box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
    }
  }
  .contact__dialog-body{
    overflow-x: hidden;
    font-size: 16px;
    padding: 0 20px;
    @media #{$sm-and-up} {
        max-height: 500px;
    }
    @media #{$xs-and-down} {
        height: calc(100vh - 51px);
        padding: 0px;
    }
  }
  .content {
    margin-top: 10px;
    width: 450px; 
    .tfa__body__div {
        padding: 15px;
        .content-div {
            margin-top: 10px;
            div {
                display: flex;
                width: 100%;
                .content-span {
                    margin-left: 10px;
                }
                img {
                    margin-left: 15px;
                }
            }

            input#otpCode {
                height: 24px;
                font-family: $primary-font;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                border: none;
                text-align: left;
                border-bottom: 1px solid #e1e1e1;
                color: #888888;
                width: 50%;
                margin-left: 23px;
                margin-top: 10px;
                outline: 0;
                &.ng-invalid {
                  border-color: $color-primary;
                }
            }

            .barcode_text {
              color: $color-primary;
              cursor: pointer;
            }

            .margin-left-20 {
              margin-left: 20px;
            }

            .margin-top-15 {
              margin-top: 15px;
            }

            .detail_value {
              margin-left: 5px;
              font-weight: bold;
            }

            .margin-bottom-15 {
              margin-bottom: 15px;
            }
        }
    }
  }
}