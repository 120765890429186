
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.export_contact_dialog {
    height: 400px;
    width: 400px;
    @media #{$xs-and-down} {
        height: 100%;
        width: 100%;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .contact__dialog-header {
        border-bottom: none;
        .header_lbl {
            justify-content: left;
           
        }
    }
    .contact__dialog-body { 
        padding: 0 10px;
        @media #{$xs-and-down} {
            height: calc(100vh - 92px);
        }
    }
    .contact__dialog-footer {
        border-top: none;
        align-items: flex-start;
    }
    .content {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0 10px;
        box-sizing: border-box;
        @media #{$xs-and-down} {
          padding: 0px;
        }

        .mat-radio-button {
            display: flex;
            padding: 10px 0px;
            .mat-radio-label-content {
                padding-left: 15px;
                font-size: 14px;
            }
        }

        .content-div {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .text-div {
            margin-bottom: 10px;
            span {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                color: #888;
            }
        }

        mat-form-field {
            .mat-form-field-infix {
                border-top: none;
            }
        }
    }
}