
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

vp-app-switcher {
  .switch-holder {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: 0px;
    background-color: #fff;
    grid-gap: 8px;
    z-index: 1001;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 16px 24px 0 rgba(7,29,51,.2), 0 12px 12px 0 rgba(7,29,51,.1);
    box-sizing: inherit;
    .switch-box {
      position: relative;
      height: 132px;
      width: 120px;
      cursor: pointer;
      border-radius: 8px;
      // background: rgba(0, 0, 0, 0.1);
      &:hover {
        background: #f5f8fa;
        .switch-title {
          color: #000000;
        }
      }
      .switch-icon {
        position: relative;
        margin-top: 16px;
        img {
          width: 72px;
          height: 72px;
          display: block;
          margin: 0 auto;
        }
      }
      .switch-title {
        position: relative;
        text-align: center;
        font-family: $manrop-font-family;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #606b76;
      }
    }
    @media #{$xs-and-down} {
      top: 50px;
      left: 0;
      width: 100%;
      justify-content: flex-start;
      padding-left: 0;
      padding-right: 0;
      .switch-box {
        width: 48%;
        display: block;
        text-align: center;
        background: transparent;
        .switch-icon {
          color: #666;
          position: relative;
          padding: 10px 0;
        }
      }
    }
  }
  .switch-holder-update{
    max-width: 376px;
    justify-content: flex-start;
    .switch-box-update{
      display: inline-block;
      .switch-title{
        position: unset;
        line-height: 24px;
        font-weight: 700;
        margin-top: 8px;
      }
    }
  }
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 48px;
  left: 0px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

vp-install-app {
  .dialog {
    &__actions {
      padding-top: 12px;
      float: right;
      button {
        text-transform: uppercase;
      }
    }
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-word;
}