
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact_detail_dialog {
    height: 500px;
    width: 535px;
    @media #{$xs-and-down} {
        height: 100%;
        width: 100%;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .contact__dialog-body {
        padding: 0;
        position: relative;
    }
    .content {
        height: 445px;
        overflow: auto;
        @media #{$xs-and-down} {
            height: 100%;
        }
        .parent-item {
            position: relative;
            height: 160px;
            border-bottom: 1px solid #e1e1e1;
            background: rgba(0, 0, 0, 0.5);
            .background-imge-avatar {
                position: absolute;
                height: 160px;
                width: 100%;
            }
            .parent-item-user-name {
                position: relative;
                text-align: center;
            }
            .user-avtar {
                .avatar {
                    &.list-avatar {
                        border-radius: 50%;
                        overflow: hidden;
                        height: 100px;
                        width: 100px;
                        line-height: 100px;
                        font-size: 20px;
                        text-align: center;
                        display: inline-block;
                        color: white;
                        margin-top: 15px;
                        border: 1px solid white;
                        @media #{$xs-and-down} {
                            height: 94px;
                            width: 94px;
                            line-height: 94px;
                        }
                    }
                    .profile-avtar {
                        font-size: 45px;
                    }
                }
            }
            .user-name {
                span {
                    font-size: $large-font-size;
                    color: white;
                    font-family: $manrop-font-family;
                    font-weight: bold;
                    text-shadow: 0 0 6px rgba(0, 0, 0, 0.55);
                    @media #{$xs-and-down} {
                        font-size: $regular-font-size;
                    }
                    &.mobile-recent-activity-button {
                        display: none;
                        @media #{$xs-and-down} {
                            display: inline-flex;
                            position: absolute;
                            right: 14px;
                            bottom: -2px;
                            border: 1px solid white;
                            background: white;
                            border-radius: 50%;
                            height: 30px;
                            width: 30px;
                            .mat-icon {
                                color: $theme-color;
                                align-self: center;
                                margin: 0 0 0 5px
                            }
                        }
                    }
                }
            }
            .star-item {
                background: white;
                text-align: center;
                border-radius: 50%;
                position: absolute;
                left: 55%;
                width: 28px;
                height: 26px;
                top: 22px;
                align-items: center;
                z-index: 999;
                @media #{$xs-and-down} {

                }
                .mat-icon {
                    height: 18px;
                    width: 18px;
                    font-size: 18px;
                    line-height: 24px;
                    color: #888;
                    cursor: pointer;
                    &.favorite-mark {
                        color: #ffa800;
                    }
                    @media #{$xs-and-down} {
                        height: 24px;
                        width: 24px;
                        font-size: 24px;
                        line-height: 24px;
                    }
                }
            }
        }
        .contact-detail-info {
            .info-item {
                display: flex;
                width: 100%;
                border-bottom: 1px solid #e1e1e1;
                padding: 12px 0 12px 0;
                .icon {
                    width: 10%;
                    margin-left: 15px;
                    .mat-icon {
                        font-size: 20px;
                        &.location-icon {
                            cursor: pointer;
                        }
                    }
                }
                .detail {
                    width: 90%;
                    .mat-icon {
                        &.seprator {
                            font-size: 10px;
                            margin: 0 0 0 10px;
                            color: #888888;
                        }
                    }
                    .address-item {
                        margin: 0 0 10px 0;
                    }
                    .phone-item {
                        margin: 0 0 10px 0;
                    }
                    .email-item {
                        margin: 0 0 10px 0;
                    }
                    a {
                        text-decoration: none;
                        color: $theme-color;
                    }
                    &.list-chips {
                        width: 80%;
                        .mat-chip {
                            border-radius: 3px;
                            color: $white-color;
                            font-size: 11px;
                            font-family: $sans-pro-font-family;
                            min-height: 18px;
                            .mat-icon {
                                font-size: 10px;
                                color: $white-color;
                                line-height: 1.8;
                            }
                            &.tag-list-chip {
                                background: #888888;
                            }
                        }
                    }
                }
            }
        }
        .operation-stripe {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #b7b7b7;
            border-bottom: 1px solid #b7b7b7;
            padding: 10px;
            @media #{$xs-and-down} {
                overflow: auto;
            }
            .item {
                text-align: center;
                .icon {
                    .mat-icon {
                        height: 24px;
                        width: 24px;
                        font-size: 24px;
                        color: $theme-color;
                        @media #{$xs-and-down} {
                            font-size: 30px;
                            height: 30px;
                            width: 30px;
                        }
                    }
                }
                .text {
                    font-size: 14px;
                    font-family: $sans-pro-font-family;
                    @media #{$xs-and-down} {
                        font-size: 12px;
                        width: 100px;
                        margin: 10px 0 10px 0;
                    }
                }
            }
        }
        .recent-activity {
            margin: 15px 0 15px 0;
            @media #{$xs-and-down} {
                margin: 0px;
            }
            .mat-tab-group {
                .mat-ink-bar {
                    background: $theme-color;
                }
                .mat-tab-label {
                    &.mat-tab-label-active {
                        color: $theme-color;
                        opacity: 1;
                    }
                }
            }
            .mat-accordion {
                .mat-expansion-panel-header-title {
                    .panel-item {
                        font-weight: bold;
                    }
                }
            }
        }


        .detail-contact {
            display: block;
            @media #{$xs-and-down} {
                display: none;
            }
            .mat-tab-group {
                .mat-ink-bar {
                    background: $theme-color;
                    height: 3px;
                }
                .mat-tab-label {
                    opacity: 1;
                    .mat-tab-label-content {
                        font-family: $manrop-font-family;
                        color: #888888;
                    }
                    &.mat-tab-label-active {
                        .mat-tab-label-content {
                            color: $theme-color;
                            opacity: 1;
                            font-weight: bold;
                        }
                    }
                }
                .mat-tab-list {
                    border-bottom: 1px solid #f1f1f1;
                }
            }
            .mat-tab-body {
                padding-bottom: 10px;
            }
            .detail-contact-tab {
                width: 95%;
                margin-left: 4%;
                .detail-caption-title {
                    font-weight: bold;
                    font-size: 14px;
                    margin: 15px 0 25px 0;
                    font-family: $manrop-font-family;;
                }
                .detail-item {
                    display: flex;
                    align-items: center;
                    margin: 16px 0 0 0;
                    .icon {
                        width: 7%;
                        @media #{$xs-and-down} {
                            width: 10%;
                        }
                        .mat-icon {
                            color: #888888;
                            line-height: 18px;
                            height: 18px;
                            width: 18px;
                            font-size: 18px;
                        }
                        &.list-chips-icon {
                            align-self: baseline;
                        }
                        &.margin-top-15 {
                            margin-top:15px;
                        }
                    }
                    .detail {
                        width: 95%;
                        @media #{$xs-and-down} {
                            width: 90%;
                        }
                        font-size: $medium-font-size;
                        font-family: $sans-pro-font-family;
                        .lable-detail {
                            font-size: 12px;
                            font-family: $sans-pro-font-family;
                            color: #888888;
                        }
                        .mat-divider {
                            margin: 1% 0 0 0;
                            color: #e1e1e1;
                        }
                        .type {
                            color: #888888;
                        }
                        .mat-icon {
                            font-size: 5px;
                            color: #888888;
                            width: 6px;
                            margin: 0 2px 0 2px;
                            height: 6px;
                        }
                        &.list-chips {
                            .mat-chip {
                                border-radius: 3px;
                                color: $white-color;
                                font-size: 11px;
                                font-family: $sans-pro-font-family;
                                min-height: 18px;
                                .mat-icon {
                                    font-size: 10px;
                                    color: $white-color;
                                    line-height: 1.8;
                                }
                                &.tag-list-chip {
                                    background: #888888;
                                }
                                span.name {
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 200px;
                                }
                            }
                        }
                        .multi-field {
                            display: flex;
                            align-items: center;
                        }
                        .width-20 {
                            width: 30%;
                            @media #{$xs-and-down} {
                                width: auto;
                            }
                        }
                    }
                    &.projects-item {
                        align-items: unset;
                        .icon {
                            margin: 10px 0 0 0;
                        }
                        .detail-data {
                            display: flex;
                            flex-direction: column;
                            span {
                                margin: 3px 0 0 0;
                            }
                        }
                    }
                }
            }

            .recent-activity {
                .mat-tab-group {
                    .mat-ink-bar {
                        background: $theme-color;
                        height: 3px;
                        width: 111px !important;
                    }
                    .mat-tab-label {
                        opacity: 1;
                        min-width: 110px;
                        .mat-tab-label-content {
                            font-family: $manrop-font-family;
                            color: #888888;
                        }
                        &.mat-tab-label-active {
                            .mat-tab-label-content {
                                color: $theme-color;
                                opacity: 1;
                                font-weight: bold;
                            }
                        }
                    }
                    .mat-tab-list {
                        border-bottom: 1px solid #f1f1f1;
                    }
                }
                .mat-tab-body-wrapper {
                   // height: calc(100vh - 365px);
                }
                .recent-contact-tab {
                    .recent-item {
                        cursor: pointer;
                        .left {
                            margin: 0px 10px 0 10px;
                            .avatar-item {
                                vp-avatar {
                                    .list-avatar {
                                        height: 36px;
                                        width: 36px;
                                        line-height: 36px;
                                        // margin: 0 12px 0 10px;
                                    }
                                }
                            }
                        }
                        .right {
                            .title {
                                font-size: 14px;
                                font-family: $sans-pro-font-family;
                                .item-type {
                                    color: #888888;
                                }
                                .title-content {
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    max-width: 365px;
                                    display: inline-flex;
                                    font-family: $sans-pro-font-family;
                                }
                            }
                            .time {
                                font-size: 12px;
                                font-family: $sans-pro-font-family;
                                color: #888888;
                                font-family: $sans-pro-font-family;
                            }
                        }
                        .mat-divider {
                            margin: 1% 0 0 1%;
                            color: #e1e1e1;
                        }
                        .recen-item-inner {
                            display: flex;
                            margin: 10px 0 0 0;
                        }
                    }
                }
            }

        }

        .mobile-detail {
            display: none;
            @media #{$xs-and-down} {
                display: block;
            }
            &.sticky-header {
                position: absolute;
                background: white;
                z-index: 9999;
                width: 100%;
                top: 0;
                .detail-contact-tab {
                    margin-top: 60px;
                }
                .mat-tab-body-wrapper {
                    min-height: 100vh;
                }
            }
            .mat-tab-group {
                .mat-ink-bar {
                    background: $theme-color;
                    height: 3px;
                }
                .mat-tab-label {
                    opacity: 1;
                    .mat-tab-label-content {
                        font-family: $manrop-font-family;
                        color: #888888;
                    }
                    &.mat-tab-label-active {
                        .mat-tab-label-content {
                            color: $theme-color;
                            opacity: 1;
                            font-weight: bold;
                        }
                    }
                }
                .mat-tab-list {
                    border-bottom: 1px solid #f1f1f1;
                }
                .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
                    display: none;
                }
            }
            .mat-tab-body-wrapper {
                min-height: calc(100vh - 360px);
            }
            .mat-tab-body {
                padding-bottom: 10px;
            }

            .detail-contact-tab {
                width: 100%;
                .detail-caption-title {
                    font-weight: bold;
                    font-size: 14px;
                    margin: 15px 0 25px 10px;
                    font-family: $manrop-font-family;;
                }
                .detail-item {
                    display: flex;
                    align-items: center;
                    margin: 15px 10px;
                    .icon {
                        width: 7%;
                        @media #{$xs-and-down} {
                            width: 10%;
                        }
                        .mat-icon {
                            color: #888888;
                            line-height: 18px;
                            height: 18px;
                            width: 18px;
                            font-size: 18px;
                        }
                        &.list-chips-icon {
                            align-self: baseline;
                        }
                        &.margin-top-15 {
                            margin-top:15px;
                        }
                    }
                    .detail {
                        width: 95%;
                        @media #{$xs-and-down} {
                            width: 90%;
                        }
                        font-size: $medium-font-size;
                        font-family: $sans-pro-font-family;
                        .lable-detail {
                            font-size: 12px;
                            font-family: $sans-pro-font-family;
                            color: #888888;
                        }
                        .type {
                            color: #888888;
                        }
                        .mat-icon {
                            font-size: 5px;
                            color: #888888;
                            width: 6px;
                            margin: 0 2px 0 2px;
                            height: 6px;
                        }
                        &.list-chips {
                            .mat-chip {
                                border-radius: 3px;
                                color: $white-color;
                                font-size: 11px;
                                font-family: $sans-pro-font-family;
                                min-height: 18px;
                                .mat-icon {
                                    font-size: 10px;
                                    color: $white-color;
                                    line-height: 1.8;
                                }
                                &.tag-list-chip {
                                    background: #888888;
                                }
                                span.name {
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 200px;
                                }
                            }
                        }
                        .margin-top-15 {
                            margin-top:15px;
                        }
                        .width-20 {
                            width: 30%;
                            @media #{$xs-and-down} {
                                width: auto;
                            }
                        }
                    }
                    &.projects-item {
                        align-items: unset;
                        .icon {
                            margin: 10px 0 0 0;
                        }
                        .detail-data {
                            display: flex;
                            flex-direction: column;
                            span {
                                margin: 3px 0 0 0;
                            }
                        }
                    }
                }
                .mat-divider {
                    margin: 1% 0 0 0;
                    color: #e1e1e1;
                }
            }

            .recent-activity {
                .mat-tab-group {
                    .mat-ink-bar {
                        background: $theme-color;
                        height: 3px;
                        width: 80px !important;
                    }
                    .mat-tab-label {
                        opacity: 1;
                        min-width: 80px;
                        .mat-tab-label-content {
                            font-family: $manrop-font-family;
                            color: #888888;
                        }
                        &.mat-tab-label-active {
                            .mat-tab-label-content {
                                color: $theme-color;
                                opacity: 1;
                                font-weight: bold;
                            }
                        }
                    }
                    .mat-tab-list {
                        border-bottom: 1px solid #f1f1f1;
                    }
                }
                .mat-tab-body-wrapper {
                   // height: calc(100vh - 365px);
                }
                .recent-contact-tab {
                    .recent-item {
                        cursor: pointer;
                        .left {
                            margin: 0px 10px 0 10px;
                            .avatar-item {
                                vp-avatar {
                                    .list-avatar {
                                        height: 36px;
                                        width: 36px;
                                        line-height: 36px;
                                        // margin: 0 12px 0 10px;
                                    }
                                }
                            }
                        }
                        .right {
                            .title {
                                font-size: 14px;
                                font-family: $sans-pro-font-family;
                                .item-type {
                                    color: #888888;
                                }
                                .title-content {
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    font-family: $sans-pro-font-family;
                                }
                            }
                            .time {
                                font-size: 12px;
                                font-family: $sans-pro-font-family;
                                color: #888888;
                                font-family: $sans-pro-font-family;
                            }
                        }
                        .mat-divider {
                            margin: 1% 0 0 1%;
                            color: #e1e1e1;
                        }
                        .recen-item-inner {
                            display: flex;
                            margin: 10px 0 0 0;
                        }
                    }
                }
            }
        }
        
    }
}