
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

body {
    font-family: $regular-font-family;
}
vp-header {
    mat-toolbar {
      @media #{$sm-and-up} {
        .mat-toolbar-row, .mat-toolbar-single-row {
         // box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.27);
         // z-index: 1;
        }
        }
        height: 48px;
        bottom:10px;
        min-height: $header-height;
        &.mat-primary {
            background: $theme-color;
            color: $font-color-white;
            @media screen and (max-width: 767px) {
              height: 51px;
            }
            @media #{$sm-and-down} {
              &.is-list-view {
                position: relative;
                top: 0;
                z-index: 0;
                @media (orientation: landscape) {
                  position: relative;
                }
                &.with-search {
                  position: relative;
                }
              }
            }
        }
        &.mat-toolbar-multiple-rows {
          min-height: 48px;
        }
        .sidebar-menu {
          @media #{$md-and-up} {
            display: none;
          }
        }
        mat-toolbar-row{
          &.mobile-header{
            padding:0 5px;
            min-height: 45px;
            // border-bottom: 1px solid #888888;
          }
        }
        .mat-toolbar-row, .mat-toolbar-single-row {
          padding: 0;
        }
    }
    .apps-button, .mat-icon-button{
      width: 48px;
      height: 48px;
      margin-right: 16px;
      
    }
    .user-menu {
      position: fixed;
      width: calc(100vw - 20%);
      height: 100vh;
      background: $background-color;
      z-index: 9999;
      top: 0;
      left: 0;
      @media #{$md-and-up} {
        display: none;
      }
      &__mask {
        position: fixed;
        height: 100vh;
        background: rgba(0,0,0,0.5);
        right: 0;
        top: 0;
        width: 20%;
        z-index: 999;
     }
      &__profile {
        height: 185px;
        background-size: 100%;
        background-position: 42% 78%;
        color: $font-color-white;
        text-align: left;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        box-shadow: inset 10px 10px 100px #000000;
        &.hide-avatar {
          justify-content: center;
        }
        &__avatar {
          padding-top: 35px;
          display: flex;
          align-items: center;
          padding-left: 14px;
          .vnc-avatar {
            height: 64px;
            width: 64px;
            line-height: 60px;
            border: 2px solid #fff;
            color: #fff;
            box-sizing: border-box;
            .status {
              bottom: 3px;
              right: 3px;
              width: 12px;
              height: 12px;
            }
            img {
              max-height: 60px;
              height: 60px;
              width: 60px;
            }
          }
        }
        h5 {
          font-size: $medium-font-size;
          padding-left: 20px;
          margin-top: 0;
          overflow: hidden;
          margin-bottom: 20px;
        }
      }
      &__options {
        overflow: auto;
        height: calc(100vh - 206px);  
        padding-top: 20px;
        .sub-list {
          padding-top: 20px;
          padding-left: 34px;
          width: 205px;
          .sidebar-item {
          display: flex;
          height: 48px;
          outline: none;
          .sub-item-icon {
            padding-right: 10px;
          }
          }
        }
        .expander-mat-content {
          display: grid;
          width: 100%;
          .expander-panel {
            display: flex;
            .mat-icon {
              margin-right: 10px;
            }
            span {
              width: 60%;
            }
            .expander-icon {
              width: 40%;
              .mat-icon {
                margin: 0;
              }
            }
          }
        }
        .mat-list-item {
          min-height: 48px;
          height: auto;
        }
        mat-icon {
          font-size: 18px;
          margin-right: 10px;
        }
        span.option-name {
          font-size: $regular-font-size;
          font-weight: 400;
          padding-left: 10px;
          // padding-bottom: 6px;
        }
      }
    }

    .mobile-header {
      input {
        font-size: 16px;
        background-color: $header-background-color;
        color: #888888;
        outline: none;
        border: none;
        height: 38px;
        padding-left: 8px;
        margin-top: 1px;
      }
      .domain-logo-mobile {
        img {
          height: 30px;
          width: 30px;
          vertical-align: middle;
        }
      }
      #clearSearchTextBtn {
        mat-icon {
          font-size: 20px;
          color: rgba(0, 0, 0, 0.2);
        }
      }
      #moreOptionBtn {
        mat-icon {
          font-size: 20px;
          color: #000;
        }
      }
      &.is-mobile-search {
        height: 50px;
      }
    }
    .end-icons{
      width: 100%;
      display: none;
      align-items: center;
      margin-left: 16px;
      svg {
        path{
          fill: #ffffff;
        }
      }
    }
    .avatar-profile-header{
      vp-avatar {
        .avatar{
          &.list-avatar{
            height: 32px;
            width: 32px;
          }
        }
      }
    }
    .avatar-div {
      margin: 8px 16px 0 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
      }
    }
    vp-avatar {
      @media #{$xs-and-down} {
        .avatar{
          &.list-avatar{
            height: 80px;
            width: 80px;
            line-height: 80px;
            font-size: 35px;
            border: 2px solid #fff;
          }
        }
      }
    }
}


.logo-title {
  font-family: "Montserrat";
  font-size: 24px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  .vnc{
    padding-left:8px;
    font-weight: bold;
    border-left: 1px solid rgba(255,255,255,0.4);
    &.postfach {
      font-size: 17px;
    }
  }
  .mail{
    font-weight: 300;
  }
  img {
    height: 32px;
  }
  sup {
    font-weight: 300;
  }
}

.username {

  font-family: $regular-font-family;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: right;
  color: $header-background-color;
  cursor: pointer;
}

.notification-count{
  position: absolute;
  border: 2px solid $header-background-color;
  line-height: 1;
  border-radius: 32px;
  padding: 2px 4px;
  background: $header-notification-count-color;
  right: 9px;
  font-size: 12px;
}

.header-btn-logo{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.header-center{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.header-end{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 0px;
  height: 48px;
}

.search-bar {
  align-items: center;
  position: absolute;
  display: flex;
  flex-shrink: 1;
  height: 36px;
  padding: 0 12px;
  border-radius: 8px;
  width:240px;
  right: 68px;
  background-color: var(--vnc-search-bar-bg-color, #285f8f);
  &.vnc-search-bar--active {
    width: calc(100% - 434px);
    transition: background-color var(--transition-duration, var(--transition-duration-default)) linear;
    input{
      width: calc(100% - 100px);
      color: white;
    }
    .bottom-line {
      opacity: 1;
      width: calc(100% - 24px);
    }
    #searchBtn, button.filter-selected-btn{
      svg g{
        fill: white;
      }
    }
    button#clearSearchBtn{
      svg g path{
        fill: white;
      }
    }
    &:hover{
      #searchBtn, button.filter-selected-btn{
        svg g{
          fill: rgba(255,255,255,.5);
        }
      }
      button#clearSearchBtn{
        svg g path{
          fill: rgba(255,255,255,.5);
        }
      }
    }
  }
  .bottom-line {
    position: absolute;
    z-index: 99;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;

    width: 10%;
    height: 2px;
    background-color: var(--vnc-search-bar__bottom-line-color, #388eda);
    opacity: 0;
    transition: opacity var(--transition-duration, var(--transition-duration-default)) linear, width .4s ease-in; 
  }
  input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 0 80px 0 8px;
    position: relative;
    border-radius: 8px;
    background-color: transparent;
    color: #ffffff80;
    line-height: 24px;
    font-weight: 400;
    left: 16px;
    height: 32px;
    font-stretch: normal;
    font-style: normal;
    margin-top: 0;
  }
  #searchBtn {
    border-radius: 0;
    padding: 0;
    margin: 0;
    min-height: 32px;
    color: gray;
    height: 32px;
    width: 20px;
    position: absolute;
    left: 15px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .mat-icon {
      font-size: 20px;
      color: rgba(255,255,255,.5);
    }
    &:hover{
      svg g{
        fill: white;
      }
    }
  }
  button#clearSearchBtn {
    width: 16px;
    position: absolute;
    display: flex;
    right: 40px;
    min-width: 24px;
    padding: 0;
    mat-icon {
      font-size: 20px;
      color: rgba(255,255,255,.5);
      line-height: 18px;
      height: 18px;
      width: 18px;
    }
    &:hover{
      svg g path{
        fill: white;
      }
    }
  }
  button.drop-down-btn {
    width: 16px;
    position: absolute;
    display:flex;
    right: 12px;
    min-width: 24px;
    padding: 0;
    mat-icon {
      font-size: 20px;
      color: #575757;
    }
    &:hover{
      mat-icon{
        color: white;
      }
    }
  }
  button.filter-selected-btn {
    width: 16px;
    position: absolute;
    display: flex;
    right: 12px;
    min-width: 24px;
    padding: 0;
    mat-icon {
      font-size: 20px;
      color: rgba(255,255,255,.5);
    }
    &:hover{
      svg g{
        fill: white;
      }
    }
  }
  #searchOptionsMenu {
    color: #575757;
    width: 45px;
    height: 38px;
    position: absolute;
    right: 15px;
    mat-icon {
      font-size: 16px;
    }
    .search-option-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .search-option-icon {
        width: 24px;
        position: relative;
        display: flex;
        align-items: center;
        #sharedIcon {
          color: white;
          font-size: 8px;
          background-color: #29b6f6;
          text-align: center;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          width: 10px;
          line-height: 10px;
          height: 10px;
          position: absolute;
          right: 0;
          top: 15px;
          padding-left: 2px;
          box-sizing: border-box;
        }
        #dropDownIcon {
          margin-top: -5px;
        }
      }
    }
  }
}
.save-search-dialg-btn {
    position: absolute;
    z-index: 9999;
    left: 60%;
    top: 8%;
    background: white;
    height: 62px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    border-radius: 8px;
    width: 28%;
    button {
      color: $theme-color;
      font-weight: bold;
      font-size: $medium-font-size;
      span {
          font-family: $manrop-font-family;
      }
  }
  .mat-input-element {
    border-radius: 6px;
    height: 37px;
    margin: 0 0 0 10px;
    padding: 0 5px 0 5px;
    border: 1px solid $theme-color;
    width: 64%;
    color: #000000;
    font-size: $medium-font-size;
    font-weight: normal;
  }
}
.show-save-lbl {
    position: absolute;
    left: 53%;
    font-weight: normal;
    font-size: 14px;
    background: black;
    border-radius: 6px;
    opacity: 0.7;
    padding: 0 10px 0 10px;
}
.advance-search-btn {
  button {
    min-width: 36px;
    width: 36px;
    background: #5a95c9;
    padding: 0;
    margin: 0 0 0 5px;
  }
}
.header-menu {
  background-color: $header-background-color;
  button {
    background-color: $header-background-color;
  }
  mat-divider {
    background-color: $header-background-color;
    color: $header-color-gray;
  }
}

.vnc_logo {
  height: 40px;
  &.is-blue-theme {
    height: 50px;
  }
}
.dfb-logo-text {
  font-size: 14px;
  margin-left: -7px;
  font-weight: bold;
}

.mobile-search {
  background-color: $header-background-color;
  mat-toolbar-row {
    padding: 0 2px;
    background-color: $header-background-color;
  }
  input {
    font-size: 15px;
    background-color: $header-background-color;
    color: $header-color-gray;
    outline: none;
    border: none;
  }
  button {
    background-color: $header-background-color;
    color: $header-color-gray;
    outline: none;
    border: none;
  }
}

.vnc-mail-mobile {
  font-weight: bolder;
  padding-left: 8px;
  font-family: "Montserrat";
  font-size: 24px;
  .bluetheme {
    .logo {
      width: 65px;
      height: 32px;
    }
  }
}
.brand_name {
  width: 100%;
  display: block !important;
}
.bluetheme {
  display: flex;
  width: 100%;
  align-items: center;
  .logo {
    width: 32px;
    height: 32px;
  }
  .brand-name {
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
  }
  .mobile_profile {
    vp-avatar {
      .avatar.profile {
        width: 24px;
        height: 30px;
        margin-top: 9px
      }
      .profile-avtar {
        font-size: 15px;
        line-height: 2;
      }
    }
  }
  .mail_count {
    color: #fff;
    font-size: 10px;
    background: #f12923;
    height: 18px;
    width: 18px;
    display: inline-block;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    margin: 0 15px 0 0;
    vertical-align: middle;
  }
}

.vnc-mail-mobile-sub {
  padding-left:2px;
  font-weight: 300;
  .brand_mail {
    font-family: "Montserrat";
    font-size: 24px;
  }
  .mail_count {
    color: #FFFFFF;
    font-size: 10px;
    background: #f12923;
    height: 18px;
    width: 18px;
    display: inline-block;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    margin: 0 0 0 9px;
    vertical-align: middle;
  }
}
.search-button {
  position: absolute;
  margin-left: -40px;
}

.mat-option{
  background-color: $header-background-color;
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled){
  background: $option-hover-background-color;
  color: #000000;
}

.option-img{
  height: 32px;
  float: right;
  margin-top: 10px;
}

.divider-opt{
  border-top: 2px solid #dbdbdb;
}

.option-span{
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: $font-color-black;
  font-size: 12px
}

.mat-autocomplete-panel{
  min-width: 357px;
  overflow-x: visible;
  overflow-y: auto;
  &.mobile-autocomplete{
    @media #{$sm-and-down} {
      top:8px;
      margin: 0 0 0 -4px;
      min-width: 100vw;
      border-top: 1px solid rgba(0, 0, 0, 0.5);
      .mat-option{
        padding: 0 0 0 24px;
        .mat-icon{
          color:$header-border-color;
        }
        small{
          font-size: 14px;
        }
      }
    }
  }
}
.mobile-autocomplete{
  left: -41px;
  top: 25px;
}

vp-avatar .avatar.desktop-profile  {
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #fff;
  width: 40px;
  height: 40px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  line-height: 40px;
  background-color: #ffc208;
  cursor: pointer;
  vertical-align: middle;
  img {
    width: 40px;
    height: 40px;
  }
}

.avatar-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}
.domain-logo {
  margin: 0 0 0 4px;
  img {
    height: 40px;
    width: 40px;
  }
}

.mail_desktop_settings_menu{
  .mat-menu-item{
    &:hover{
      background: #eeeeee;
    }
    span{
      font-size: 14px;
    }
    .mat-icon{
      font-size: 18px;
      height: 18px;
      width: 18px;
      display: inline;
    }
    svg:not(.mat-menu-submenu-icon) {
      height: 18px;
      width: 18px;
      vertical-align: middle;
    }
    img {
      height: 18px;
      width: 18px;
      vertical-align: middle;
      padding-right: 16px;
      padding-bottom: 2px;
    }
  }
  .menu-link {
    text-decoration: none;
  }
}


#searchMailOption, #searchContactOption, #searchBriefcaseOption, #sharedItemOption, #searchCalendarOption {
  color: #575757;
  mat-icon {
    font-size: 16px;
    color: #575757;
    margin-right: 10px;
    margin-top: 5px;
  }
  .mat-button-focus-overlay, .mat-button-ripple.mat-ripple {
    border-radius: 0;
  }
  &.selected, &:hover {
    background-color: #eeeeee;
  }
}
#sharedItemOption {
  span {
    margin-left: 11px;
  }
}

.profile-logout-menu{
  width: 280px;
  .profile-menu{
    height: 64px !important;
    display: flex;
    align-items: center;
    align-items: flex-start;
    vp-avatar .avatar.list-avatar{
      margin: 0 8px 0 12px;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
    }
    .profile-text{
      display: inline-block;
      position: relative;
      vertical-align: top;
      #uname{
        font-family: $manrop-font-family;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 20px;
        letter-spacing: normal;
        color: #000;
      }
      #uemail{
        font-family: $sans-pro-font-family;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 14px;
        letter-spacing: normal;
        color: #7a858f;
      }
    }
  }
  button, .mat-menu-item{
    align-items: center;
    display: flex;
    height: 36px;
    padding: 0;
    width: 280px;
    .mat-menu-submenu-icon {
      display: none;
    }
    &:hover{
      background-color: #f7f8fa;
    }   
  }
  .cdk-focused, .cdk-mouse-focused{
    background: none !important;
    .mat-focus-indicator {
      display: none !important;
    }
  }
  .mat-menu-item-submenu::after {
    content: "\F0142"!important;
    right: 20px!important;
    border: none!important;
    font-size: 16px;
    color: #888;
    font-family: "Material Design Icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    position: absolute;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  vnc-icon-container{
    margin-right: 8px;
    padding: 6px 0 6px 12px; 
  }
  span{
    margin: 2px 0;
    font-family: $sans-pro-font-family;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: -0.14px;
    color: #131313;
  }
  .logout-btn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: unset;
    #btn-log{
      border-radius: 4px;
      padding: 0 10px;
      width: 72px;
      height: 24px;
      border: none;
      margin: 0 24px 0 0;
      border-radius: 4px;
      cursor: pointer;
      #txt {
        width: 52px;
        height: 24px;
        font-family: $manrop-font-family;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        text-transform: uppercase;
        letter-spacing: normal;
        text-align: center;
        color: #337cbd;
      }
      &:hover{
        #btn-log{
          background-color: #f2f9fe;
        }
      }
    }
    .mat-menu-ripple{
      display: none;
    }
    &:hover{
      background-color: transparent;
    }
  }
}

.header-help-menu, .header-legal-notice-menu{
  align-items: center;
  display: flex;
  width: 240px;
  button, .mat-menu-item{
    align-items: center;
    height: 36px;
    display: flex;
    padding: 8px 0;
    width: 240px;
    &:hover{
      background-color: #f7f8fa;
    }
  }
  vnc-icon-container{
    margin-right: 8px;
    padding: 6px 0 6px 12px; 
  }
  span{
    margin: 2px 0;
    font-family: $sans-pro-font-family;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    letter-spacing: -0.14px;
    color: #131313;
  }
}

.searchOptions {
  margin-left: -12px;
  .mat-divider {
    border-color: rgba(0, 0, 0, 0.36);
    margin: 0 15px;
  }
  .mat-checkbox-inner-container-no-side-margin {
    margin-left: 3px;
    width: 10px;
    height: 10px;
  }
}

.cdk-overlay-pane.advanced-search-dialog {
  width: 100vw;
  height: 100vh;
  .mat-dialog-container {
    padding: 0;
    border-radius: 0;
    .mail__dialog-header {
      .header-title {
        display: flex;
        width: 100%;
      }
      input#mobileSearchInput {
        font-size: 18px;
        text-align: left;
        color: #ffffff;
        background: transparent;
        border: none;
        outline: 0;
        margin-top: 1px;
      }
    }
    .mail__dialog-body {
      padding-top: 30px;
      font-size: 16px;
      padding-left: 0;
      h4 {
        font-size: 16px;
        text-transform: uppercase;
        background: #f8f8f8;
        height: 40px;
        line-height: 40px;
        padding: 0 24px;
      }
      .search-group {
        margin-bottom: 25px;
        padding-right: 24px;
        padding-left: 24px;
        .mat-select {
          margin-top: 15px;
        }
        input#mobileSearchInput {
          outline: 0;
          border: 0;
          border-bottom: 1px solid $color-primary;
          font-size: 16px;
          color: #888;
        }
      }
      label {
        font-size: 12px;
        color: #888;
      }
    }
  }
}

.searchInOption {
  .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled),
  .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: #eee;
  }
  .mat-option.mat-active {
    color: black;
  }
}
@media screen and (min-width: 768px) {
  .mobile-header {
    display: none!important;
  }
  .desktop-header {
    display: flex!important;
  }
}
@media screen and (max-width: 767px) {
  .desktop-header {
    display: none!important;
  }
  .mobile-header {
    display: flex!important;
  }
}

.overlay{
  background: none;
}