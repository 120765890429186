
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.contact-image-cropper-dialog {
    height: 450px;
    width: 400px;
    @media #{$xs-and-down} {
        height: 450px;
        width: 100%;
    }

    .close-button {
        margin: 0 10px 0 0;
        display: flex;
    }
    .contact__dialog-header {
        @media #{$xs-and-down} {
            background: #fff;
            color: #000;
            justify-content: space-between;
            border-bottom: 1px solid #e1e1e1;
        }
        .header_lbl {
            @media #{$xs-and-down} {
                color: #000;
                font-size: 16px;
                margin-left: 10px;
            }
            justify-content: end;
            margin: 0 0 0 15px;
        }
        .mat-icon {
            &.gray-color {
                color: #888888;
            }
        }
    }
    .footer-buttons {
        display: flex;
        padding: 0 20px;      
        button {
            cursor: pointer;
            color: #317bbc;
            font-size: 16px;
            &.disabled {
                color: #888;
            }
        }
    }
    .contact__dialog-body {
        padding-left: 0px;
        @media #{$xs-and-down} {
            height: 350px;
        }
        .user-profile__upload-avatar {
            text-align: center;
            @media #{$sm-and-up} {
                height: 350px;
            }
           // padding: 0 10px;
            @media #{$xs-and-down} {
               height: 350px;
            }
            .upload-text-button{
                cursor: pointer;
                box-shadow: none;
                outline: none;
                border-radius: 20px;
                padding: 8px 24px 8px 24px;
                color:white;
                font-size: 14px;
                font-weight: 600;
                margin-top: 25px;
                background: #0071bb;
                font-weight: bold;
                border: none;
            }
            .remove-text-button{
                cursor: pointer;
                box-shadow: none;
                outline: none;
                border-radius: 20px;
                padding: 8px 24px 8px 24px;
                color:white;
                font-size: 14px;
                font-weight: 600;
                margin-top: 25px;
                background:#bb0000;
                font-weight: bold;
                border: none;
            }

            .cropper-image {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                img {
                    height: 350px;
                    width: 350px;
                    border-radius: 0;
                    border: none;
                }
                .no-avatar-item {
                    background: #f7f7f7;
                    height: 100%;
                    width: 100%;
                    .icon-div {
                        border: 1px solid white;
                        border-radius: 50%;
                        display: block;
                        height: 230px;
                        width: 230px;
                        margin: 14% auto;
                        background: #f1f1f1
                    }
                    .mat-icon {
                        font-size: 150px;
                        color: #888888;
                        margin: 11% 53% 0 0;
                    }
                }
            }

            
            .avatar.list-avatar {
                border-radius: 50%;
                overflow: hidden;
                height: 48px;
                width: 48px;
                line-height: 52px;
                font-size: 20px;
                text-align: center;
                display: inline-block;
                color: white;
                background-color: #ededed;
                cursor: pointer;
                .mat-icon {
                    vertical-align: middle;
                    color: #888;
                }
            }
            .upload-text {
                font-size: 15px;
                text-align: center;
                color: #317bbc;
                cursor: pointer;
                margin-top: 10px;
            }
        }

        .save_crop_image_div {
            text-align: center;
        }
        .save_crop_image_btn{
            cursor: pointer;
            box-shadow: none;
            outline: none;
            border-radius: 20px;
            padding: 8px 24px 8px 24px;
            color:white;
            font-size: 14px;
            font-weight: 600;
            margin-top: 25px;
            background: #0071bb;
            font-weight: bold;
            border: none;
            &:disabled{
                background:#888888;
            }
        }
        img {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            border: 1px solid #e1e1e1;
        }
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
        @media #{$xs-and-down} {
            margin: 0 5% 0 5%;
            height: 450px;
        }
    }
    image-cropper {
        .cropper {
            &.rounded {
                outline-color: #FFFFFF !important;
                opacity: 0.6;
            }
            &:focus {
                border-color: white;
                border-width: 2px;
            }
            .move {
                border: 2px solid #FFFFFF !important;
            }
            &:after {
                border: 2px solid #FFFFFF !important;
            }
        }
        .overlay {
            background: transparent;
        }

        .source-image {
            background: #e1e1e1;
        }
    } 
}