
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.confirm_contactplus_dialog {
    .contact__dialog {
        position: relative;
        width: 400px;
        @media #{$xs-and-down} {
            height: 100%;
            width: 100%;
        }
        &-header {
            padding-left: 24px;
            border-bottom: none;
            .header_lbl {
                justify-content: left;
                @media #{$xs-and-down} {
                    margin-left: 0px;
                    color: #000;
                }
            }
            @media #{$xs-and-down} {
                background: #fff;
            }
        }
        &-body {
            padding: 16px 24px;
            height: auto;
            .content {
                span {
                    font-size: $regular-font-size;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    font-family: $sans-pro-font-family;
                    line-height: 20px;
                }
            }
            .inversable {
                font-size: $regular-font-size;
                font-family: $sans-pro-font-family;
                color: #e10000;
                @media #{$xs-and-down} {
                    font-size: $medium-font-size;
                }
            }
        }
        &-footer {
            border-top: none;

            .buttons-div {
                padding-right: 8px;
            }
            button {
                font-family: $manrop-font-family;
                cursor: pointer;
                color: #317bbc;
                font-size: 16px;
                &.disabled {
                    color: #888;
                }
            }
        }
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        @media #{$xs-and-down} {
            margin: 0 5% 0 5%;
        }
    }
}