
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

 .undo_changes_dialog {
    height: 446px;
    width: 500px;
    @media #{$xs-and-down} {
        height: 100%;
        width: 100%;
    }
    .mobile-back-button {
        margin: 0 5px 0 5px;
    }
    .header_lbl {
        font-size: 18px;
        font-weight: bold;
        font-family: $manrop-font-family;
        margin-left: 14px;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .contact__dialog-header {
        border-bottom: none;
        .header_lbl {
            justify-content: left;
            @media #{$sm-and-up} {
                padding-left: 20px;
            }
        }
    }
    .contact__dialog-body {
        padding: 0 20px;
        @media #{$xs-and-down} {
            height: calc(100vh - 92px);
        }
    }
    .contact__dialog-footer {
        border-top: none;
    }
    .content {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;

        .mat-radio-button {
            display: flex;
            padding: 10px 0px;
            @media #{$xs-and-down} {
                padding: 20px 0px;
            }
            .mat-radio-label-content {
                padding-left: 15px;
                font-size: 14px;
                font-family: $sans-pro-font-family;
                @media #{$xs-and-down} {
                    font-size: 16px;
                }
            }
        }

        .content-div {
            margin-top: 10px;
            margin-bottom: 10px;
            &.margin-30 {
                margin-top: 30px;
            }
            .mat-icon {
                &.help-icon {
                    vertical-align: bottom;
                    margin: 0 0 0 2px;
                    opacity: 0.5;
                    @media #{$xs-and-down} {
                        display: none;
                    }
                }
            }
        }

        .text-div {
            margin-bottom: 10px;
            span {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                font-family: $sans-pro-font-family;
                @media #{$xs-and-down} {
                    font-size: 16px;
                }
                &.undo_changes_from {
                    @media #{$xs-and-down} {
                        display: none;
                    }
                }
                &.change_content_list_text {
                    @media #{$xs-and-down} {
                        color: #888888;
                    }
                }
            }
        }

        mat-form-field {
            .mat-form-field-infix {
                border-top: none;
            }
        }
        .date-all-items {
            display: flex;
            align-items: center;
            .mat-form-field {
                width: 100px;
                @media #{$xs-and-down} {
                    width: 70px;
                }
                margin: 0 0 0 18px;
                font-size: 14px;
            }
        }
        .warning-msg {
            font-size: 12px;
            margin-left: 20px;
            color: #e10000;
        }
    }
}