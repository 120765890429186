
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.import_contact_dialog {
    height: auto;
    width: 400px;
    @media #{$xs-and-down} {
        height: 100%;
        width: 100%;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .contact__dialog-header {
        border-bottom: none;
        min-height: 20px;
        margin: 15px 0 0 0;
        @media #{$xs-and-down} {
            min-height: 48px;
            margin: 0;
        }
        .header_lbl {
            justify-content: left;
            display: flex;
            align-items: center;
            .mat-icon {
                margin: 0 0 0 10px;
                opacity: 0.5;
                @media #{$xs-and-down} {
                    display: none;
                }
            }
            @media #{$sm-and-up} {
                padding-left: 20px;
            }
        }
    }
    .contact__dialog-body { 
        padding: 0 20px;
        @media #{$xs-and-down} {
            height: calc(100vh - 92px);
        }
    }
    .contact__dialog-footer {
        border-top: none;
    }
    .content {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;

        .content-div {
            margin-top: 15px;
            margin-bottom: 15px;
            .lable-select {
                cursor: pointer;
                color: $theme-color;
                font-size: 14px;
                font-family: $manrop-font-family;
                font-weight: bold;
            }
            .no-file-chosen {
                font-weight: bold;
                font-size: 14px;
                font-family: $manrop-font-family;
                margin: 0 0 0 30px;
            }
            &.description {
                font-size: 14px;
                font-family: $sans-pro-font-family;
            }
            &.description-mobile {
                font-size: $regular-font-size;
                font-family: $sans-pro-font-family;
            }
            &.import-contact-msg {
                text-align: center;
                margin-top: 30px;
                span {
                    height: 41px;
                    border-radius: 30px;
                    background-color: $active-link-color;
                    color: #fff;
                    text-decoration: none;
                    outline: none;
                    text-transform: uppercase;
                    line-height: 2.5;
                    font-weight: 700;
                    font-size: $regular-font-size;
                    font-family: $manrop-font-family;
                    width: 187px;
                    display: inline-block;
                }
            }
        }
        .desktop-view {
            display: block;
            @media #{$xs-and-down} {
                display: none;
            }
        }
        .mobile-view {
            display: none;
            @media #{$xs-and-down} {
                display: block;
            }
        }
        .csv-header-items {
            display: inline-grid;
            height: 280px;
            @media #{$xs-and-down} {
                height: auto;
            }
            overflow: auto;
            width: 100%;
            .mat-form-field {
                width: 80%;
            }
        }
    }

    .select-list-block {
        .select-list {
            cursor: pointer;
            color: $theme-color;
            font-size: 14px;
            font-family: $manrop-font-family;
            font-weight: bold;
            background-color: white;
            border: none; 
            padding: 0;
            margin-right: 8px;
        }
        .selected-list-count {
            font-size: 14px;
            font-family: Source Sans Pro;
        }
    }

    
}