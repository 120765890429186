
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact_mobile_about_dialog {
    height: 100%;
    width: 100%;
    .mobile-back-button {
        margin: 0 5px 0 5px;
    }
    .header_lbl {
        font-size: 18px;
        font-weight: bold;
        font-family: $manrop-font-family;
        margin-left: 14px;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
    }
    .mat-list {
        .mat-list-item {
            .mat-icon {
                font-size: 20px;
                margin: 0 25px 0 0;
            }
        }
        span {
            font-size: 16px;
            font-family: $sans-pro-font-family;
            a {
                text-decoration: none;
                color: #000000;
            }
        }
    }
    svg {
        height: 16px;
        width: 16px;
        vertical-align: middle;
        padding-right: 25px;
        padding-bottom: 2px;
    }
}