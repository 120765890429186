
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact-group-detail-dialog {
    height: 500px;
    width: 535px;
    @media #{$xs-and-down} {
        height: 100%;
        width: 100%;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .contact__dialog-body {
        padding: 0;
    }
    .content {
        padding: 10px;
        height: 425px;
        // @media #{$xs-and-down} {
        //     height: 100%;
        //     width: 100%;
        // }
        .group-detail {
            display: flex;
            align-items: center;
            .group-name {
                display: flex;
                align-items: flex-start;
                margin: 0 20px 0 20px;
                span {
                    margin: 0 0 0 15px;
                    font-size: 18px;
                    font-weight: bold;
                }
                .full-name {
                    word-break: break-all;
                }
            }
            .group-member {
                align-self: baseline;
                display: flex;
                .mat-icon {
                    color: #888888;
                    margin: -2px 11px 0 0;
                }
            }
        }
        .member-detail {
            .mat-expansion-panel {
                border: 1px solid #e1e1e1;
                margin: 10px 0 10px 0;
                .panel-item {
                    display: flex;
                    align-items: center;
                    .user-avatar-item {
                        .list-avatar {
                            height: 25px;
                            width: 25px;
                            line-height: 25px;
                            font-size: 15px;
                            font-weight: normal;
                        }
                    }
                    .full-name {
                        margin: 0 0 0 10px;
                    }
                }
                .email-member {
                    display: flex;
                    .icon {
                        .mat-icon {
                            font-size: 20px;
                            color: #888888;
                        }
                    }
                    .email-address {
                        a {
                            text-decoration: none;
                            color: $theme-color;
                        }
                    }
                }
            }
        }
    }
}