
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

@import 'contactsplus/app_switch';
@import 'avatar';
@import 'contactsplus/contact_list_layout';
@import 'contactsplus/contact_list_item';
@import 'contactsplus/sidebar';
@import 'contactsplus/ngx_context_menu';
@import 'contactsplus/contact_floating_window';
@import 'contactsplus/create_contact_dialog';
@import 'contactsplus/dialog';
@import 'contactsplus/add_contact_button';
@import 'contactsplus/animations';
@import 'contactsplus/contact_image_cropper_dialog';
@import 'contactsplus/confirm_contactplus_dialog';
@import 'contactsplus/create_contact_group_dialog';
@import 'contactsplus/apps_dialog';
@import 'contactsplus/about_us_dialog';
@import 'contactsplus/contact_mobile_about_dialog';
@import 'contactsplus/service_desk_dialog';
@import 'contactsplus/help_faq_dialog';
@import 'contactsplus/legal_notice_dialog';
@import 'contactsplus/contact_settings_dialog';
@import 'contactsplus/profile_avatar_dialog';
@import 'contactsplus/contact_detail_dialog';
@import 'contactsplus/contact_group_detail_dialog';
@import 'contactsplus/contact_list';
@import 'contactsplus/contact_list_detail';
@import 'contactsplus/duplicate_contact';
@import 'contactsplus/color_control_dialog';
@import 'contactsplus/create_contact_folder';
@import 'contactsplus/export_contact_dialog';
@import 'contactsplus/create_tag_dialog';
@import 'contactsplus/trash_contact';
@import 'contactsplus/undo_changes_dialog';
@import 'contactsplus/import_contact_dialog';
@import 'contactsplus/contact_lists_dialog';
@import 'contactsplus/contact_list_operation_dialog';
@import 'contactsplus/add_to_lists_dialog';
@import 'contactsplus/advance_search_dialog';
@import 'contactsplus/search_in_apps_dialog';
@import 'contactsplus/search_in_lists_dialog';
@import 'contactsplus/contact_group_dialog';
@import 'contactsplus/mobile_group_detail_dialog';
@import 'contactsplus/list_contact_dialog';
@import 'contactsplus/tag_contact_dialog';
@import 'contactsplus/add_to_tag_dialog';
@import 'contactsplus/search_in_tags_dialog';
@import 'contactsplus/save_search_dialog';
@import 'contactsplus/advance_search_component';
@import 'contactsplus/tfa-settings';
@import 'contactsplus/recent_activity_dialog';

.mat-menu-item {
    background: white;
}
.mat-menu-content {
  background: white;
}

.mat-menu-submenu-icon {
  right: 0;
  width: 5px;
  height: 10px;
}
.contact-container {
    display: flex;
    height: calc(100vh - 48px);
    @media #{$xs-and-down} {
        height: calc(100vh - 51px);
        width: 100vw;
    }
    overflow: hidden;
}
.contact-sidebar {
    width: $sidebar-width;
    background: white;
    border-right: 1px solid #dbdbdb;
    position: relative;
    .resizable-icon {
      position: absolute;
      right: -4px;
      top: 50%;
      margin-top: -15px;
      z-index: 999;
    }

    .toggle-panel-btn {
      cursor: pointer;
      -webkit-appearance: none;
      background: #fff;
      width: 24px;
      height: 24px;
      line-height: 24px;
      align-items: center;
      border: 1px solid #dadada;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      right: -14px;
      top: 5%;
      margin-top: -15px;
      z-index: 999;
      display: flex;
      outline: none;
      &.collapsed {
        left: 48px;
      }
      .mat-icon {
        vertical-align: middle;
        font-size: 16px;
        color: #888888;
        margin: 0 0 0 -4px;
      }
      &:hover {
        background-color: #34a0f7;
        border: none;
        .mat-icon {
          color: white;
        }
      }
    }
}
.contact-component {
    background: white;
    width: calc(100% - 60px);
    @media #{$xs-and-down} {
        width: 100%;
        overflow: hidden;
    }
    @media #{$sm-and-down} {
      width: 100%;
    }
    &.sidebarExpanded {
      width: calc(100% - 260px);
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
}

.sidebar-collapsed {
    width: 60px;
  }

  .sidebar-expanded {
    margin: 0;
    width: $sidebar-width;
  }

  .preserve-font-size-ios {
    -webkit-text-size-adjust: 100%;
  }

  .vp-module {
    background: #e5e5e5;
  }

  .mat-dialog-container {
    background: #fff;
    overflow: hidden;
    @media #{$sm-and-up} {
      border-radius: 8px;
      box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
    }
  }

  .cdk-overlay-pane {
    .show-field-select {
        .mat-select-panel:not([class*=mat-elevation-z]) {
            box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
        }
        .mat-option{
            &.show-field-select {
                background: white;
                color: black;
            }
        }
        &.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
            background: white;
            color: black;
        }
    }
    .mat-optgroup {
      &.export-group {
        .mat-optgroup-label {
          background: $white-color;
          font-size: 12px;
          color: #888888;
        }
      }
    }
    .mat-select-panel {
      background: white;
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
    }
  }

  .mat-snack-bar-handset{
    .mat-snack-bar-container {
      &.mobile_snackbar{
        @media #{$xs-and-down} {
          max-width: 69%;
        }
      }
    }
  }
  .mat-snack-bar-container{
    background: #000000;
    color: $font-color-white;
   &.mobile_snackbar{
    border-radius: 25px;
    @media #{$xs-and-down} {
      position: fixed;
      right: 66px;
      background: rgba(0,0,0, 0.7);
      text-align: center;
      bottom: 8px;
    }
    .mat-simple-snackbar{
      display: flex;
      justify-content: center;
      @media #{$xs-and-down} {
        display: inline-flex;
        span {
          max-width: 65vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      span {
        white-space: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
   }
  }

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #fff;
  background: #29b6f6;
}

.app-disabled {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: "#fff";
  z-index: 10000000001;
  padding: 80px 0px;
  text-align: center;
  mat-spinner {
    margin-top: 15%;
    .mat-spinner__circle {
      border-color: $theme-color;
    }
  }
}

.disable-select, .disable-select input{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hide {
  display: none;
}

.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
  background-color: #fff;
  color: rgba(0,0,0,.87);
}

.mat-calendar-body-selected {
  background-color: #3f51b5;
  color: #fff !important;;
}
.mat-calendar-body-cell-content {
  color: rgba(0,0,0,.87);
  border-color: transparent;
}
.contact-plus-mat-menu {
  &:has(.contact-plus-menu-item-icon) {
    width: 200px;
    box-shadow: $shadow_dropdown;
    .mat-menu-divider-block {
      color: $neutral_300;
      margin: 5px 16px;
    }
  }
  .mat-menu-item {
    padding-right: 40px;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-family: $sans-pro-font-family;
    font-style: normal;
    letter-spacing: normal;
    color: #000;
    height: 37px;
    line-height: 2;
    &:has(.contact-plus-menu-item-icon) {
      display: flex;
      padding-bottom: 6px;
      padding-top: 6px;
      padding-left: 12px;
    }
    .mat-icon {
      color: #888888;
      line-height: 0.8;
      &.checked-menu-item {
        position: absolute;
        right: 12px;
        margin: 10px 0 0 0;
        color: $theme-color;
      }
    }
    &:hover {
      background: $neutral_20;
    }
    &:disabled{
      opacity: 0.5;
    }
    .contact-plus-menu-item-icon {
      display: inline-block;
      height: 24px;
      width: 24px;
      margin-right: 8px;
      svg {
        width: 18px;
        height: 18px;
        padding: 3px;
      }
    }
    .contact-plus-menu-item-label {
      line-height: 24px;
    }
  }
}

.mat-tooltip {
  color: #eaeef2!important;
  background-color: #131313!important;
  font-size: 12px!important;
  word-wrap:break-word;
  margin: 0 !important;
  font-family: 'Source Sans Pro';
  border-radius: 4px;
  height: 32px;
  line-height: 32px;
  padding: 0 16px!important;
  box-shadow: 0 8px 16px 0 rgba(8, 45, 61, 0.2), 0 4px 12px 0 rgba(8, 45, 61, 0.1)!important;
  z-index: 99999999;
}

.contact-lists-mat-menu {
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  width: 300px;
  max-width: 400px !important;
  border-radius: 6px !important;
  .list-scrollbar-item {
    max-height: 130px;
    overflow: auto;
  }
  .mat-menu-item {
    &.contact-list-item {
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .mat-icon {
        &.check-item {
          font-size: 18px;
          color: #888888;
          float: right;
          margin: 10px 0 0;
	  text-align: right;
        }
      }
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 190px;
        overflow: hidden;
      }
    }
    &.add-list {
      border-top: 1px solid #e1e1e1;
      color: $theme-color;
      font-weight: bold;
      font-family: $manrop-font-family;
      .mat-icon {
        color: $theme-color;
      }
    }
    &.search-item {
      .mat-form-field {
        width: 100%;
      }
      border-bottom: 1px solid #e1e1e1;
    }
    &.apply-new-button {
      border-top: 1px solid #e1e1e1;
      color: $theme-color;
      font-weight: bold;
      font-family: $manrop-font-family;
      .mat-icon {
        color: $theme-color;
      }
      .add-list {
	padding: 0px 16px 0px 0px;
      }
    }
  }
}

.contact-tags-mat-menu {
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  width: 300px;
  max-width: 400px !important;
  border-radius: 6px !important;
  .list-scrollbar-item {
    max-height: 130px;
    overflow: auto;
  }
  .mat-menu-item {
    &.contact-list-item {
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      .mat-icon {
        &.check-item {
          font-size: 18px;
          color: #888888;
          float: right;
          margin: 10px 0 0;
	  text-align: right;
        }
      }
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 190px;
        overflow: hidden;
      }
    }
    &.add-list {
      border-top: 1px solid #e1e1e1;
      color: $theme-color;
      font-weight: bold;
      font-family: $manrop-font-family;
      .mat-icon {
        color: $theme-color;
      }
    }
    &.search-item {
      .mat-form-field {
        width: 100%;
      }
      border-bottom: 1px solid #e1e1e1;
    }
    &.apply-new-button {
      border-top: 1px solid #e1e1e1;
      color: $theme-color;
      font-weight: bold;
      font-family: $manrop-font-family;
      .mat-icon {
        color: $theme-color;
      }
      .add-list {
	padding: 0px 16px 0px 0px;
      }
    }
  }
}

.confirm_contactplus_dialog {
  @media #{$xs-and-down} {
    width: 100%;
  }
}

.contact-plus-operation-mat-menu {
  .mat-menu-item {
    padding-right: 40px;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-family: $sans-pro-font-family;
    font-style: normal;
    letter-spacing: normal;
    color: #000000;
    height: 45px;
    line-height: 2;
    .mat-icon {
      line-height: 0.8;
    }
    &:hover {
      background: #e1e1e1;
    }
    &:disabled{
      opacity: 0.5;
    }
  }
}

.contact-plus-select-item-mat-menu {
  .mat-menu-item {
    padding-right: 40px;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-family: $sans-pro-font-family;
    font-style: normal;
    letter-spacing: normal;
    color: #000000;
    height: 45px;
    line-height: 2;
    .mat-icon {
      line-height: 0.8;
    }
    &:hover {
      background: #e1e1e1;
    }
    &:disabled{
      opacity: 0.5;
    }
  }
}
.contact-auto-complete {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  &.mat-autocomplete-panel {
    width: 50%;
  }
  .mat-option {
      vp-avatar {
        .avatar.list-avatar {
          height: 32px;
          width: 32px;
          line-height: 32px;
          font-size: 16px;
          vertical-align: middle;
          margin: 0 8px 0 0;
        }
      }
  }
}


div#contact-detail {
  width: 100%;
  overflow: hidden;
}


.is-iframe {
  .contact-container {
    height: 100vh;
  }
  vp-sidebar .contact-sidebar-main-division {
    height: calc(100vh - 57px);
  }
  vp-contact-plus-list-component .mat-table {
    height: 100vh;
  }
  vp-contact-plus-list-detail-component .single-contact-detail {
    height: calc(100vh - 45px);
  }
  vp-contact-plus-list-detail-component .single-contact-detail .detail-contact .mat-tab-body-wrapper {
    height: calc(100vh - 248px);
  }
}


.mat-snack-bar-container{
  &.snackbar-notificiation {
    background: #000000;
    padding: 0;
    border-radius: 8px !important;
    box-shadow: 0 8px 16px 0 rgba(8, 45, 61, 0.2);
    background: #191a1c;
    font-family: "Source Sans Pro";
    font-size: 14px;
    color: white;
    .snackbar-notificiation-block{
      display: flex;
      align-items: center;
      padding: 0 24px;
      border-radius: 8px;
      justify-content: space-between;
      box-shadow: 0 8px 16px 0 rgba(8, 45, 61, 0.2), 0 4px 12px 0 rgba(8, 45, 61, 0.1);
      background-color: #191A1C;
      min-height: 48px;
      .message-part {
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #FFFFFF;
        display: flex;
        align-items: center;
      }
      .action {
        display: flex;
        align-items: center;
      }
    }
  }
}

.table-row-skeleton-loader {
  display: flex;
  margin-left: 20px;
}

.gs-dropdown-item-skeleton-table {
  display: flex;
  align-items: center;
  padding-left: 12px;
  position: relative;
  overflow: hidden;
  height: 56px;
  width: 25%;
  .avatar-part  {
    width: 36px;
    height: 36px;
    min-height: 36px;
    border-radius: 50%;
    background: #F5F8FA;
    overflow: hidden;
  }
  .data-part {
    width: calc(100% - 60px);
    padding-left: 12px;
    overflow: hidden;
    .header-part.loading {
      background: #F5F8FA;
      height: 22px;
      width: 75%;
      border-radius: 4px;
      overflow: hidden;
    }
  }
  .loading {
    position: relative;
  }
  .loading::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, #ecf0f3, transparent);
    animation: loading 2s infinite;
  }
}

mwlResizable {
  box-sizing: border-box;
}

.resize-active {
  .resize-handle-right {
    background: #47a9ff;
  }
}
.resize-handle-right{
  display: flex;
  position: absolute;
  z-index: 2;
  height: calc(100vh - 48px);
  align-items: center;
  justify-content: center;
  left: 270px;
  width: 1px;
  cursor: col-resize;
  left: 321px;

  vnc-icon-container{
    .vnc-icon-container{
      cursor: col-resize;
    }
  }

  .talk-drag-icon{
    display: block;
  }

  .talk-drag-icon-hover {
    display: none;
  }
}

.resize-handle-right:hover {
  .talk-drag-icon{
    display: none;
  }

  .talk-drag-icon-hover {
    display: block;
  }
}

.overlay-while-resizing{
  width: 97vw;
  height: calc(-48px + 100vh);
  position: absolute;
  z-index: 2;
  display: none;
}
