
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact-app-switch-dialog {
    width: 100%;
    height: 100%;
    .mat-dialog-container {
        padding: 0;
    }
    .contact__dialog-header {
        .app-dilog-header{
            width: 100%;
            padding: 0;
        }
    }
    vp-app-switcher {
        .switch-holder {
            padding-left: 22px;
            @media #{$sm-and-down} {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}