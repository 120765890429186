
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

vp-contact-plus-list-detail-component {
    .close {
        text-align: right;
        margin: 15px 16px 0 0;
        cursor: pointer;
        .mat-icon {
            color: #888888;
        }
    }
    .single-contact-detail {
        padding: 0;
        border-left: 1px solid #e0e0e0;
        height: calc(100vh - 109px);
        &.is-tag-list-header {
            height: calc(100vh - 164px);
            .detail-contact {
                .mat-tab-body-wrapper {
                    height: calc(100vh - 365px);
                }
            }
        }
        margin: 10px 0 0 0;
        overflow: hidden;
        .detail-user-avatar {
            display: flex;
            justify-content: space-between;
            background: #607d8b;
            height: 82px;
            align-items: center;
            vp-avatar {
                .list-avatar {
                    height: 64px;
                    width: 64px;
                    line-height: 64px;
                    margin: 0 12px 0 0;
                    border: 1px solid #fff;
                    font-size: 30px;
                    .mat-icon {
                        vertical-align: middle;
                        font-size: 30px;
                        height: 30px;
                        width: 30px;
                    }
                }
            }
            .star-item {
                background: white;
                text-align: center;
                border-radius: 50%;
                position: relative;
                left: 63px;
                width: 18px;
                height: 18px;
                top: -23px;
                align-items: center;
                .mat-icon {
                    height: 18px;
                    width: 18px;
                    line-height: 18px;
                    color: #888;
                    cursor: pointer;
                    &.favorite-mark {
                        color: #ffa800;
                    }
                }
            }
            .left {
                display: flex;
                align-items: center;
                color: $white-color;
                margin: 0 0 0 12px;
                .name-jobtitle {
                    .full-name {
                        font-family: $manrop-font-family ;
                        font-size: $large-font-size;
                        font-weight: bold;
                    }
                    .job-title {
                        font-size: $medium-font-size;
                        span {
                            font-family: $sans-pro-font-family;
                        }
                        .mat-icon {
                            font-size: 6px;
                            margin: 0 0 0 2px;
                            width: 8px;
                        }
                    }
                }
            }
            .right {
                button {
                    &.mat-mini-fab {
                        background: transparent;
                        box-shadow: none;
                    }
                }
            }
        }
        .operation-part {
            display: flex;
            background: #f2f2f2;
            height: 70px;
            cursor: pointer;
            align-items: center;
	        border: solid 1px #e0e0e0;
            justify-content: space-around;
            .mat-icon {
                color: $theme-color;
            }
            .item {
                text-align: center;
                width: 10%;
                .text {
                    font-size: $small-font-size;
                    font-family: $manrop-font-family;
                    color: $theme-color;
                    @media screen and (max-width: 959px) {
                        display: none
                    }
                }
            }
        }
        .detail-contact {
            .mat-tab-group {
                .mat-ink-bar {
                    background: $theme-color;
                    height: 3px;
                }
                .mat-tab-label {
                    opacity: 1;
                    min-width: 110px;
                    .mat-tab-label-content {
                        font-family: $manrop-font-family;
                        color: #888888;
                    }
                    &.mat-tab-label-active {
                        .mat-tab-label-content {
                            color: $theme-color;
                            opacity: 1;
                            font-weight: bold;
                        }
                    }
                }
                .mat-tab-list {
                    border-bottom: 1px solid #f1f1f1;
                }
            }
            .mat-tab-body-wrapper {
                height: calc(100vh - 305px);
            }
            .mat-tab-body {
                padding-bottom: 10px;
            }
            .detail-contact-tab {
                width: 95%;
                margin-left: 2%;
                .detail-caption-title {
                    font-weight: bold;
                    font-size: 14px;
                    margin: 15px 0 25px 0;
                    font-family: $manrop-font-family;;
                }
                .background-color-item {
                  &:hover {
                        background: #f2f2f2;
                        .background-text-color {
                            color: $active-link-color;
                            cursor: pointer;
                        }
                        .icon {
                            .mat-icon {
                                color: $active-link-color;
                                &.map-marker {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .detail-item {
                    display: flex;
                    align-items: center;
                    padding: 16px 0 0 8px;
                    .address-text-size {
                        font-family: $sans-pro-font-family;
                    }
                    &:hover {
                        background: #f2f2f2;
                        cursor: pointer;
                    }
                    .icon {
                        width: 5%;
                        &.map-icon-item {
                            align-self: flex-start;
                            margin-top: 15px;
                        }
                        .mat-icon {
                            color: #888888;
                            line-height: 18px;
                            height: 18px;
                            width: 18px;
                            font-size: 18px;
                        }
                        &.list-chips-icon {
                            align-self: baseline;
                        }
                        &.margin-top-15 {
                            margin-top:15px;
                        }
                    }
                    .detail {
                        width: 95%;
                        font-size: $medium-font-size;
                        font-family: $sans-pro-font-family;
                        .lable-detail {
                            font-size: 12px;
                            font-family: $sans-pro-font-family;
                            color: #888888;
                        }
                        .mat-divider {
                            margin: 1% 0 0 0;
                            color: #e1e1e1;
                        }
                        .type {
                            color: #888888;
                        }
                        .mat-icon {
                            font-size: 5px;
                            color: #888888;
                            width: 6px;
                            margin: 0 2px 0 2px;
                            height: 6px;
                        }
                        &.list-chips {
                            .mat-chip {
                                border-radius: 3px;
                                color: $white-color;
                                font-size: 11px;
                                font-family: $sans-pro-font-family;
                                min-height: 18px;
                                .mat-icon {
                                    font-size: 10px;
                                    color: $white-color;
                                    line-height: 1.8;
                                }
                                &.tag-list-chip {
                                    background: #888888;
                                }
                            }
                        }
                        .multi-field {
                            display: flex;
                            align-items: center;
                        }
                        .width-20 {
                            width: 20%;
                        }
                    }
                    &.projects-item {
                        align-items: unset;
                        .icon {
                            margin: 10px 0 0 0;
                        }
                        .detail-data {
                            display: flex;
                            flex-direction: column;
                            span {
                                margin: 3px 0 0 0;
                            }
                        }
                    }
                }
            }
            /* Recent Activity */
            .recent-activity {
                &.is-tag-list-header {
                    .mat-tab-body-wrapper {
                        height: calc(100vh - 428px);
                    }
                }
                .mat-tab-group {
                    .mat-ink-bar {
                        background: $theme-color;
                        height: 3px;
                        width: 111px !important;
                    }
                    .mat-tab-label {
                        opacity: 1;
                        min-width: 110px;
                        .mat-tab-label-content {
                            font-family: $manrop-font-family;
                            color: #888888;
                        }
                        &.mat-tab-label-active {
                            .mat-tab-label-content {
                                color: $theme-color;
                                opacity: 1;
                                font-weight: bold;
                            }
                        }
                    }
                    .mat-tab-list {
                        border-bottom: 1px solid #f1f1f1;
                    }
                }
                .mat-tab-body-wrapper {
                    height: calc(100vh - 365px);
                }
                .recent-contact-tab {
                    .recent-item {
                        cursor: pointer;
                        .left {
                            margin: 0px 10px 0 10px;
                            .avatar-item {
                                vp-avatar {
                                    .list-avatar {
                                        height: 36px;
                                        width: 36px;
                                        line-height: 36px;
                                        // margin: 0 12px 0 10px;
                                    }
                                }
                            }
                        }
                        .right {
                            .title {
                                font-size: 14px;
                                font-family: $sans-pro-font-family;
                                .item-type {
                                    color: #888888;
                                }
                                .title-content {
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    font-family: $sans-pro-font-family;
                                }
                            }
                            .time {
                                font-size: 12px;
                                font-family: $sans-pro-font-family;
                                color: #888888;
                                font-family: $sans-pro-font-family;
                            }
                        }
                        .mat-divider {
                            margin: 1% 0 0 1%;
                            color: #e1e1e1;
                        }
                        .recen-item-inner {
                            display: flex;
                            margin: 10px 0 0 0;
                        }
                    }
                }
            }

            /* Single Group Contact detail display from list Desktop*/
            .add-contact {
                align-items: center;
                overflow: auto;
                max-height: calc(100vh - 270px);
                &.is-tag-list-header {
                    max-height: calc(100vh - 320px);
                }
                .contact-item-multipe {
                    display: inline-flex;
                    align-items: center;
                    border: solid 1px #e0e0e0;
                    background-color: #f2f2f2;
                    border-radius: 4px;
                    margin: 15px 15px 0 14px;
                    width: 250px;
                    height: 62px;
                    cursor: pointer;
                    .user-avatar-item {
                        position: relative;
                        vp-avatar {
                            .list-avatar {
                                height: 36px;
                                width: 36px;
                                line-height: 36px;
                                font-size: 16px;
                                margin: 0 12px 0 10px;
                                .mat-icon {
                                    font-size: 20px;
                                    vertical-align: middle;
                                    color: #fff;
                                    width: 24px;
                                    height: 24px;
                                    margin: 0px;
                                }
                            }
                        }
                        .star-item {
                            background: white;
                            text-align: center;
                            border-radius: 50%;
                            position: absolute;
                            right: 10px;
                            width: 14px;
                            bottom: 28px;
                            height: 14px;
                            align-items: center;
                            .mat-icon {
                                &.favorite-mark {
                                    color: #ffa800;
                                }
                            }
                        }
                        .mat-icon {
                            font-size: 10px;
                            height: 8px;
                            width: 10px;
                            vertical-align: super;
                            margin: 2px 0 0 0;
                            color: #888888;
                        }
                    }
                    .user-fullname {
                        font-weight: bold;
                        font-family: $manrop-font-family;
                        font-size: $regular-font-size;
                    }
                }
            }

        }
    }

    .multiple-contact-detail {
        margin: 10px 8px 0 0;
        border-left: 1px solid #e0e0e0;
        height: calc(100vh - 109px);
        &.is-tag-list-header {
            height: calc(100vh - 164px);
        }
        .operation-part {
            display: flex;
            background: #f2f2f2;
            height: 70px;
            cursor: pointer;
            align-items: center;
	    border: solid 1px #e0e0e0;
            .mat-icon {
                color: $theme-color;
                &.gray-icon {
                    color: #888888;
                }
            }
            .item {
                text-align: center;
                width: 10%;
                .text {
                    font-size: $small-font-size;
                    font-family: $manrop-font-family;
                    color: $theme-color;
                    &.gray-text {
                        color: #888888;
                    }
                    @media screen and (max-width: 1280px) {
                        display: none
                    }
                }
                &.more {
                    text-align: right;
                    padding: 0 16px 0 10%;
                }
            }
            .mat-divider {
                height: 66%;
                margin: 9px 0 0 0;
                color: #e2e2e2;
            }
        }
        .add-contact {
            align-items: center;
            overflow: auto;
            max-height: calc(100vh - 192px);
            &.is-tag-list-header {
                max-height: calc(100vh - 235px);
            }
            .contact-item-multipe {
                display: inline-flex;
                align-items: center;
                border: solid 1px #e0e0e0;
                background-color: #f2f2f2;
		border-radius: 4px;
                margin: 15px 15px 0 14px;
                width: 250px;
                height: 62px;
                cursor: pointer;
                .user-avatar-item {
                    position: relative;
                    vp-avatar {
                        .list-avatar {
                            height: 36px;
                            width: 36px;
                            line-height: 36px;
                            font-size: 16px;
                            margin: 0 12px 0 10px;
                            .mat-icon {
                                font-size: 20px;
                                vertical-align: middle;
                                color: #fff;
                                width: 24px;
                                height: 24px;
                                margin: 0px;
                            }
                        }
                    }
                    .star-item {
                        background: white;
                        text-align: center;
                        border-radius: 50%;
                        position: absolute;
                        right: 10px;
                        width: 14px;
                        bottom: 28px;
                        height: 14px;
                        align-items: center;
                        .mat-icon {
                            &.favorite-mark {
                                color: #ffa800;
                            }
                        }
                    }
                    .mat-icon {
                        font-size: 10px;
                        height: 8px;
                        width: 10px;
                        vertical-align: super;
                        margin: 2px 0 0 0;
                        color: #888888;
                    }
                }
                .user-fullname {
                    font-weight: bold;
                    font-family: $manrop-font-family;
                    font-size: $regular-font-size;
                }
            }
        }
    }
}