
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

vp-duplicate-contact-component {
    .main {
        height: calc(100vh - 64px);
        @media #{$xs-and-down} {
            height: calc(100vh - 92px);
        }
        overflow: auto;
    }
    .merge-all {

    }
    .duplicate-contacts {
        .merge-all-heading {
            display: flex;
            @media #{$xs-and-down} {
                display: none;
            }
            justify-content: space-between;
            width: 70%;
            margin: 13px auto;
            .count {
                font-size: 14px;
                color: #707070;
                font-family: $sans-pro-font-family;
                cursor: pointer;
            }
            .merge-all {
                font-size: 14px;
                color: $primary-color;
                font-family: $manrop-font-family;
                cursor: pointer;
                font-weight: bold;
            }
        }
        .border-item {
            width: 70%;
            margin: 15px auto;
            border-radius: 8px;
            border: solid 1px #cccccc;
            background-color: #f7f7f7;
            @media #{$xs-and-down} {
                width: 95%;
                background: $white-color;
            }
            &.is-hide-duplicate {
                display: none;
            }
        }
        .all-contact {
           // display: flex;
        }
        .merge-dismiss {
            text-align: right;
            border-top: 1px solid #CCCCCC;
            width: 90%;
            margin: 0 auto;
            button {
                span {
                    font-family: $manrop-font-family;
                }
                color: $primary-color;
                margin: 5px 20px 12px 0;
                @media #{$xs-and-down} {
                    margin: 3px 0 3px 0;
                    font-weight: bold;
                }
                &.merge {
                    background: $primary-color;
                    color: $white-color;
                    width: 90px;
                    @media #{$xs-and-down} {
                        color: $primary-color;
                        background: transparent;
                        width: auto;
                    }
                }
            }
        }
        .duplicate-contact-item {
            display: flex;
            flex-wrap: wrap;
            .main-contact {
                display: flex;
            }
            .avatar {
                margin: 0 10px 0 20px;
                @media #{$xs-and-down} {
                    margin: 0 10px 0 8px;
                }
                vp-avatar .avatar.list-avatar {
                    height: 36px;
                    width: 36px;
                    line-height: 36px;
                    font-size: 16px;
                    @media #{$xs-and-down} {
                        height: 42px;
                        width: 42px;
                        line-height: 42px;
                        font-size: 24px;
                    }
                }
            }
            .other-information {
                display: flex;
                flex-direction: column;
                .contact-name {
                    font-size: $medium-font-size;
                    font-weight: bold;
                    @media #{$xs-and-down} {
                        font-size: $regular-font-size;
                    }
                    span {
                        font-family: $manrop-font-family;
                    }
                }
                .email-phone {
                    font-size: $medium-font-size;
                    color: #888888;
                    span {
                        font-family: $sans-pro-font-family;
                    }
                }
            }
            .contact-item-element {
                display: flex;
                width: 50%;
                @media #{$xs-and-down} {
                    width: 100%;
                }
                table-layout: fixed;
                margin: 17px 0 9px 0;
            }
        }
    }
}
.contact-merge-duplicate-mobile-btn {
    display: none;
    @media #{$xs-and-down} {
        display: flex;
    }
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    bottom: 15px;
    right: 45px;
    text-align: right;
    z-index: 6;
    @media(max-width: $layout-breakpoint-sm) {
        right: 15px;
    }
    button {
        background: $action-menu-color;
        height: 56px;
        border-radius: 35px;
        width: 120px;
        font-size: 20px;
        color: $white-color;
        font-family: $sans-pro-font-family;
        font-weight: normal;
    }
}