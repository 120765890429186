
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

vp-avatar {
    .avatar {
      text-transform: uppercase;
      &.profile {
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid #fff;
        width: 58px;
        height: 58px;
        font-size: 26px;
        text-align: center;
        display: inline-block;
        line-height: 58px;
        background-color: $profile-background-color;
        img {
          width: inherit;
          height: inherit;
          border-radius: 50%;
          }
        }
      &.profile-dialog {
          border-radius: 50%;
          overflow: hidden;
          border: 2px solid #fff;
          width: 94px;
          height: 94px;
          font-size: 26px;
          text-align: center;
          display: inline-block;
          line-height: 94px;
          background-color: $profile-background-color;
          img {
            width: inherit;
            height: inherit;
            border-radius: 50%;
            }
          }
        &.list-avatar {
          border-radius: 50%;
          overflow: hidden;
          height: 43px;
          width: 43px;
          line-height: 44px;
          font-size: 20px;
          text-align: center;
          display: inline-block;
          color: white;
          margin-top: 1px;
          background-color: $profile-background-color;
          @media #{$xs-and-down} {
            height: 42px;
            width: 42px;
            line-height: 42px;
            font-size: 24px;
            font-weight: normal;
          }
          img {
            width: inherit;
            height: inherit;
            border-radius: 50%;
            }
          }
        &.mail-spam-avatar {
          border-radius: 50%;
          overflow: hidden;
          height: 43px;
          width: 43px;
          line-height: 41px;
          text-align: center;
          display: block;
          color: white;
          background-color: #888888;
          margin-top: 1px;
          @media #{$xs-and-down} {
            height: 38px;
            width: 38px;
            line-height: 37px;
            font-size: 14px;
            margin-top: 3px;
          }
          img {
            width: 28px;
            height: 28px;
            vertical-align: middle;
            }
        }
        &.mail-detail-avatar {
          border-radius: 50%;
          overflow: hidden;
          height: 30px;
          width: 30px;
          line-height: 30px;
          text-align: center;
          display: block;
          color: white;
          background-color: #888888;
          margin-right: 5px;
          font-size: 14px;
          margin-top: 5px;
          @media #{$xs-and-down} {
            height: 30px;
            width: 30px;
            line-height: 30px;
          }
          img {
            width: inherit;
            height: inherit;
            border-radius: 50%;
            }
        }
        &.mail-chip-avatar {
          border-radius: 50%;
          overflow: hidden;
          height: 24px;
          width: 24px;
          line-height: 24px;
          text-align: center;
          display: block;
          color: white;
          background-color: #888888;
          margin-right: 5px;
          font-size: 14px;
          @media #{$xs-and-down} {
            height: 24px;
            width: 24px;
            line-height: 24px;
          }
          img {
            width: inherit;
            height: inherit;
            border-radius: 50%;
            }
        }
        &.detail-mobile-avatar {
          display: inline-block;
          width: 36px;
          background-color: #00b8d4;
          font-size: 24px;
          color: #fff;
          height: 36px;
          line-height: 36px;
          border-radius: 50px;
          text-align: center;
          text-transform: uppercase;
          margin-right: 10px;
          margin-left: -6px;
          vertical-align: top;
          img {
            width: inherit;
            height: inherit;
            border-radius: 50%;
          }
        }
        &.mail-email-template-avatar {
          border-radius: 50%;
          overflow: hidden;
          height: 43px;
          width: 43px;
          line-height: 41px;
          text-align: center;
          display: block;
          color: white;
          background-color: #888888;
          margin-top: 1px;
          @media #{$xs-and-down} {
            height: 38px;
            width: 38px;
            line-height: 37px;
            font-size: 14px;
            margin-top: 3px;
          }
          .mat-icon {
            width: 24px;
            height: 24px;
            vertical-align: middle;
          }
        }
      }
    }
  