
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact_plus_advance_search_dialog {
    width: 400px;
    height: 300px;
    @media #{$xs-and-down} {
        height: 100%;
        width: 100%;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .content {
        padding: 15px;
        .item {
            cursor: pointer;
            margin: 0 0 15px 0;
            .search-in {
                font-size: $regular-font-size;
                font-family: $sans-pro-font-family;
            }
            .lists {
                font-size: $medium-font-size;
                font-family: $sans-pro-font-family;
                color: #888888;
                word-break: break-all
            }
        }
    }
}