
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.ngx-contextmenu .dropdown-menu:focus {
    outline: none;
  }
  
  .ngx-contextmenu .dropdown-menu {
    background-color: #FFFFFF;
    padding: 0;
    border-radius: 2px;
    box-shadow: 1px 2px 10px grey;
  }
  
  .ngx-contextmenu li {
    display: block;
    text-align: left;
    line-height:  25px;
    font-size: 14px;
    padding-right: 15px;
  }
  
  .ngx-contextmenu li:first-child {
    border-top:none;
  }
  
  .ngx-contextmenu a {
    color:#555;
    display: block;
    padding: 0.5em 1em;
    font-weight: 400;
    text-decoration: none;
  }
  
  .ngx-contextmenu li:hover {
    background-color: #F8F8F8;
  }