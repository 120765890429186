
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact_lists_dialog {
    width: 100%;
    height: 100%;
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .mobile-back-button {
        margin: 0 5px 0 5px;
    }
    .contact__dialog-header {
        &.search-header {
            background: $white-color;
            box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.5);
            .mat-icon {
                color: #000000;
            }
            .mat-form-field {
                height: 51px;
                width: 100%;
                color: #000000;
                margin: -12px 0 0 0;
                .mat-form-field-wrapper {
                    .mat-form-field-underline {
                        display: none;
                    }
                }
                &.mat-focused {
                    .mat-form-field-label {
                        color: rgba(0, 0, 0, 0.54);
                    }
                }
            }
        }
    }
    .content {
        padding: 10px 20px 0 15px;
        .folder-list-item {
            display: flex;
            justify-content: space-between;
            height: 48px;
            align-items: center;
            .name {
                white-space: nowrap;
                width: 80%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: $regular-font-size;
                font-family: $sans-pro-font-family;
                text-align: left;
                padding-left: 28px;
            }
            .count {
                font-size: $regular-font-size;
                color: #888888;
                font-family: $sans-pro-font-family;
                width: 10%;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-dialog {
    position: relative;
    .modal-body-contact {
        .avatar-section {
            padding: 0 0px 16px 0px;
            height: 96px;
            display: flex;

            vp-avatar {
                .list-avatar {
                    height: 96px;
                    width: 95px;
                }

                mat-icon {
                    font-size: 95px;
                    width: 96px;
                    height: 96px;
                }
            }

            .user-avatar {
                width: 96px;
                position: relative;

                .edit {
                    width: 24px;
                    height: 24px;
                    background: #27ab7f;
                    bottom: 8px;
                    position: absolute;
                    right: 0px;
                    border: solid 2px white;
                    border-radius: 50%;
                    @extend .flex-center;

                    mat-icon {
                        color: white;

                        ::before {
                            font-size: 14px;
                        }
                    }
                }

                .favourite {
                    @extend .flex-center;
                    width: 24px;
                    height: 24px;
                    background: #ffffff;
                    top: 0;
                    position: absolute;
                    right: lef;
                    border: solid 2px white;
                    border-radius: 50%;
                    box-shadow: 0 0 2px lightgrey;

                    mat-icon {
                        color: orange;
                    }
                }
            }

            .contact-info-block {
                padding-left: 12px;
                padding-top: 4px;
                max-width: 470px;
                width: 470px;
                .name {
                    font-family: Manrope;
                    font-size: 20px;
                    font-weight: 800;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.2;
                    letter-spacing: normal;
                    color: #000;
                    margin: 0;
                    margin-bottom: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .contact-info {
                    margin: 0;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    color: #8b96a0;
                    display: flex;

                    .dot {
                        width: 4px;
                        height: 4px;
                        background: #8b96a0;
                        margin: 4px;
                        border-radius: 50%;
                    }
                }
            }
        }

        .form-section {
            // height: 450px;
            padding-bottom: 30px;
           
            @media screen and (max-width: 767px) {
                .contact__dialog-body {
                    padding: 16px;
                }
            }
            .row-flex {
                flex-direction: column;
                align-items: flex-start;

                .mat-form-field-wrapper {
                    padding: 0;
                }
            }

            .right-space-div {
                width: auto;
            }

            .contact_city {
                width: calc(35% + 25px) !important;
            }

            button {
                color: #337cbd;
                padding: 0;

                span {
                    @extend .flex-center;
                }

                .mat-icon {
                    @extend .flex-center;
                    font-size: 18px;
                }
            }

            .phone-number-form-field {
                width: 100% !important;
            }

            .country-code {
                    border: 1px solid #0000001f;
                    height: 46px;
                    /* margin: 0.25em 0; */
                    margin-bottom: 1.34375em;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    padding-left: 8px;
                    color: gray;

                    .flag-address-item-div {
                        border-bottom: none;
                    }
            }
            .tags-container {
                border: 1px solid #0000001f;
                height: 46px;
                margin-bottom: 1.34375em;
                border-radius: 4px;
                display: flex;
                align-items: center;
                color: gray;
                min-height: 32px;
                height: auto;
                padding-top: 8px;
                padding-bottom: 8px;

                .chip-list-field {
                    margin-top: 0;
                    padding-left: 8px;
                }
                mat-label {
                    padding-left: 8px;
                }
                mat-chip-list {
                    .mat-chip-data{
                            background: #eaeef2;
                            height: 24px;
                            /* margin: 2px 0; */
                            /* padding: 0 0 0 29px; */
                            padding: 6px;
                            border-radius: 16px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #000000;
                    }
                }
            }
        }
    }

    .footer-section {
        background: white;
        /* opacity: 1; */
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;

        .add-more-field {
            @extend .flex-center;
            width: auto;
            color: #337cbd;

            mat-icon {
                font-size: 18px;
                display: flex;
                align-items: center;
            }

            .fields {
                width: 106px;

                .add_more {
                    font-size: 14px;
                }
            }
        }

        .desktop-footer {
            padding: 0;
        }
    }

}