
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.create-tag-dialog {
    .mat-form-field {
        width: 100%;
    }
    .content {
        margin: 5px 0 24px 0;
    }
    .actions {
        text-align: right;
        a {
            text-decoration: none;
            text-transform: uppercase;
            cursor: pointer;
            color: $dialog-operation-buttons-color;
            margin: 0 15px 0 0;
            font-weight: bold;
        }
    }
}