
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.save_search_dialog {
    width: 100%;
    height: 100%;
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .mobile-back-button {
        margin: 0 5px 0 5px;
    }
    .contact__dialog-header {
        &.search-header {
            background: $white-color;
            box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.5);
            .mat-icon {
                color: #000000;
            }
            .mat-form-field {
                height: 51px;
                width: 100%;
                color: #000000;
                margin: -12px 0 0 0;
                .mat-form-field-wrapper {
                    .mat-form-field-underline {
                        display: none;
                    }
                }
                &.mat-focused {
                    .mat-form-field-label {
                        color: rgba(0, 0, 0, 0.54);
                    }
                }
            }
        }
    }
    .content {
        padding: 10px 20px 0 15px;
        .folder-list-item {
            display: flex;
            height: 48px;
            align-items: center;
            .name {
                white-space: nowrap;
                width: 80%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: $regular-font-size;
                font-family: $sans-pro-font-family;
                text-align: left;
                padding-left: 28px;
                .magnify-glass-icon {
                    color: #888888;
                }
            }
        }
    }
}